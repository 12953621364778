import React, { useState } from "react";
import axios from "axios";
import image1 from "../../assets/images/logoIcon/logo.png";
import { Link } from "react-router-dom";

function UserSignUp() {
  const [email, setemail] = useState(" ");
  const [password, setpassword] = useState(" ");
  const [fullname, setfullname] = useState(" ");

  function handleDate(e) {
    e.preventDefault();

    //  API post method

    axios
      .post("https://jsonplaceholder.typicode.com/users", {
        email,
        password,
        fullname,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <>
      <section className="UserSignUp">
        <div class="container">
          <div class="row">
            <div class="col-sm-9 col-md-7 col-lg-7 mx-auto">
              <div class="card border-0 shadow rounded-3 my-5">
                <div class="card-body p-4 p-sm-5">
                  <div className="olxlog text-center">
                    <img src={image1} alt="" />
                  </div>
                  <h5 class="ana7r-card-title text-center mb-2 mt-4">
                    Welcome TO ana74 <br /> <br />
                  </h5>
                  <form onSubmit={handleDate}>
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        class="form-control"
                        name="full name"
                        placeholder="Full Name"
                        onChange={(e) => setfullname(e.target.value)}
                      />
                      <label for="floatingInput">Full Name</label>
                    </div>
                    <div class="form-floating mb-3">
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        placeholder="name@example.com"
                        onChange={(e) => setemail(e.target.value)}
                      />
                      <label for="floatingInput">Email address</label>
                    </div>
                    <div class="form-floating mb-3">
                      <input
                        type="password"
                        class="form-control"
                        name="password"
                        placeholder="Password"
                        onChange={(e) => setpassword(e.target.value)}
                      />
                      <label for="floatingPassword">Password</label>
                    </div>
                    <div class="d-grid">
                      <button class="btn  btn-login text-uppercase fw-bold">
                        Sign Up
                      </button>
                      <div className="term-and-condition mt-3">
                        <span>
                          &nbsp;
                          <span>Already Have An Account? </span> &nbsp;
                          <span>
                            <Link to="/login">Sign In</Link>
                          </span>
                        </span>
                      </div>
                    </div>
                    <hr class="my-4" />
                    <div class="d-grid mb-2">
                      <button
                        class="btn btn-google btn-login text-uppercase fw-bold"
                        type="submit"
                      >
                        <i class="fab fa-google me-2"></i> Sign Up with Google
                      </button>
                    </div>
                    <div class="d-grid">
                      <button
                        class="btn btn-facebook btn-login text-uppercase fw-bold"
                        type="submit"
                      >
                        <i class="fab fa-facebook-f me-2"></i> Sign Up with
                        Facebook
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UserSignUp;
