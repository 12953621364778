import React from "react";
import image1 from "../../assets/images/subcategory/649caff38d82d1687990259.jpg";
import image2 from "../../assets/images/subcategory/649cb036092be1687990326.jpg";
import image3 from "../../assets/images/subcategory/649cb05dc2a1b1687990365.jpg";
import image4 from "../../assets/images/subcategory/649cb091a7b5d1687990417.jpg";
import image5 from "../../assets/images/subcategory/649cb0b91a7bf1687990457.jpg";
import image6 from "../../assets/images/subcategory/649cb0de4467e1687990494.jpg";
import { Link } from "react-router-dom";
import tEn from "../../locales/En/translation.json";
import tara from "../../locales/Ara/translation.json";
import { createRoot } from "react-dom/client";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: tEn,
    },
    ara: {
      translation: tara,
    },
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

function CardSlider() {
  const { t } = useTranslation();
  return (
    <>
      <div className="container-fluid">
        <div
          id="carouselExampleControls"
          class="carousel carousel-dark slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-lg-4">
                  <div class="card" style={{ width: "100%" }}>
                    <img src={image1} class="card-img-top" alt="..." />
                  </div>
                  <div class="bg-warning rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                    {t("ana7r-72")}
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="card" style={{ width: "100%" }}>
                    <img src={image2} class="card-img-top" alt="..." />
                  </div>
                  <div class="bg-warning rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                    {t("ana7r-73")}
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="card" style={{ width: "100%" }}>
                    <img src={image3} class="card-img-top" alt="..." />
                  </div>
                  <div class="bg-warning rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                    {t("ana7r-74")}
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-lg-4">
                  <div class="card" style={{ width: "100%" }}>
                    <img src={image4} class="card-img-top" alt="..." />
                  </div>
                  <div class="bg-warning rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                    {t("ana7r-75")}
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="card" style={{ width: "100%" }}>
                    <img src={image5} class="card-img-top" alt="..." />
                  </div>
                  <div class="bg-warning rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                    {t("ana7r-76")}
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="card" style={{ width: "100%" }}>
                    <img src={image6} class="card-img-top" alt="..." />
                  </div>
                  <div class="bg-warning rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                    {t("ana7r-77")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
}

export default CardSlider;
