import React from "react";
import image1 from "../../assets/images/software/64a1c4331ddc41688323123.png";
import image2 from "../../assets/images/software/64a101244f3dd1688273188.png";
import image3 from "../../assets/images/software/64a1bc6f832921688321135.png";
import image4 from "../../assets/images/software/64a1bc9cb9aba1688321180.png";
import image5 from "../../assets/images/software/64a1bd4a8d54a1688321354.png";
import image6 from "../../assets/images/software/64a1bfc9926f31688321993.png";
import image7 from "../../assets/images/software/64a1bff1282331688322033.png";
import image8 from "../../assets/images/software/64a1c022d1c2b1688322082.png";
import image9 from "../../assets/images/software/64a1c2082edcc1688322568.png";
import image10 from "../../assets/images/software/64a1c2266cf211688322598.png";
import image11 from "../../assets/images/software/64a1c23f8dab41688322623.png";
import image12 from "../../assets/images/software/64a1c44d8d8551688323149.png";
import image13 from "../../assets/images/software/64a1c4649c6f61688323172.png";
import image14 from "../../assets/images/software/64a1c620dc4961688323616.png";
import image15 from "../../assets/images/software/64a1c6558cda91688323669.png";
import Userimage1 from "../../assets/images/user/profile/64a1c3614c3b01688322913.jpg";
import { Link } from "react-router-dom";

function ProductGridCard() {
  const productdata = [
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: image1,
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: image2,
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: image3,
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: image4,
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: image5,
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: image6,
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: image7,
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: image8,
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: image9,
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: image10,
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: image11,
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: image12,
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: image13,
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: image14,
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: image15,
      userimage: "",
    },
  ];
  return (
    <>
      <div className="item-card-wrapper grid-view">
        {productdata.map((data) => (
          <div class="item-card">
            <div class="item-card-thumb">
              <img src={data.image} alt="product image" />
            </div>
            <div class="item-card-content">
              <div class="item-card-content-top">
                <div class="left">
                  <div class="author-thumb">
                    <img src={Userimage1} alt="seller" />
                  </div>
                  <div class="author-content">
                    <h5 class="name">
                      <Link to="/">{data.name}</Link>
                      <span class="level-text">{data.level}</span>
                    </h5>
                    <div class="ratings">
                      <i class="fas fa-star"></i>
                      <span class="rating me-2">{data.rating}</span>
                    </div>
                  </div>
                </div>
                <div class="right">
                  <div class="item-amount">{data.amount}</div>
                </div>
              </div>
              <h3 class="item-card-title">
                <Link to="/">{data.title}</Link>
              </h3>
            </div>
            <div class="item-card-footer">
              <div class="left">
                <Link
                  to="/"
                  class="item-love me-2 loveHeartActionSoftware"
                  data-softwareid="23"
                >
                  <i class="fas fa-heart"></i>
                  <span class="give-love-amount">(1)</span>
                </Link>
                <Link to="/" class="item-like">
                  <i class="las la-thumbs-up"></i> (1)
                </Link>
                <Link to="/" class="btn--base active buy-btn">
                  <i class="las la-shopping-cart"></i> Buy Now
                </Link>
              </div>
              <div class="right">
                <div class="order-btn">
                  <Link to="/" target="__blank" class="btn--base">
                    <i class="las la-desktop"></i> Preview
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ProductGridCard;
