import React from "react";
import image1 from "../../assets/images/software/64a1c4331ddc41688323123.png";
import Userimage1 from "../../assets/images/user/profile/64a1c3614c3b01688322913.jpg";
import { Link } from "react-router-dom";

function ProductCard() {
  const productdata = [
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: "",
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: "",
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: "",
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: "",
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: "",
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: "",
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: "",
      userimage: "",
    },
    {
      name: "saller",
      level: "LV 5",
      rating: "5.00",
      amount: "$230.00",
      title: "React js Full Responsive Wesite",
      image: "",
      userimage: "",
    },
  ];
  return (
    <>
      {productdata.map((data) => (
        <div class="item-card">
          <div class="item-card-thumb">
            <img src={image1} alt="product image" />
          </div>
          <div class="item-card-content">
            <div class="item-card-content-top">
              <div class="left">
                <div class="author-thumb">
                  <img src={Userimage1} alt="seller" />
                </div>
                <div class="author-content">
                  <h5 class="name">
                    <Link to="/">{data.name}</Link>
                    <span class="level-text">{data.level}</span>
                  </h5>
                  <div class="ratings">
                    <i class="fas fa-star"></i>
                    <span class="rating me-2">{data.rating}</span>
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="item-amount">{data.amount}</div>
              </div>
            </div>
            <h3 class="item-card-title">
              <Link to="/">{data.title}</Link>
            </h3>
          </div>
          <div class="item-card-footer">
            <div class="left">
              <Link
                to="/"
                class="item-love me-2 loveHeartActionSoftware"
                data-softwareid="23"
              >
                <i class="fas fa-heart"></i>
                <span class="give-love-amount">(1)</span>
              </Link>
              <Link to="/" class="item-like">
                <i class="las la-thumbs-up"></i> (1)
              </Link>
              <Link to="/" class="btn--base active buy-btn">
                <i class="las la-shopping-cart"></i> Buy Now
              </Link>
            </div>
            <div class="right">
              <div class="order-btn">
                <Link to="/" target="__blank" class="btn--base">
                  <i class="las la-desktop"></i> Preview
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default ProductCard;
