import React from "react";
import Header from "../Header/Header";
import Filter from "../Filter/HomeSection";
import HomeSection from "../Filter/HomeSection";
import TrendingSlider from "../TrendingSlider/TrendingSlider";

function Home() {
  return (
    <>
      <HomeSection />
      <TrendingSlider />
    </>
  );
}

export default Home;
