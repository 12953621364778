import React, { useEffect } from "react";
import Logo from "../../../src/assets/images/logoIcon/logo.png";
import "./header.css";
import { Link } from "react-router-dom";
import tEn from "../../../src/locales/En/translation.json";
import tara from "../../../src/locales/Ara/translation.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: tEn,
    },
    ara: {
      translation: tara,
    },
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

function Header() {
  const { t } = useTranslation();
  const changeLang = (l) => {
    return () => {
      i18n.changeLanguage(l);
      localStorage.setItem("lang", l);
    };
  };

  useEffect(() => {
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  return (
    <>
      <header class="header-section">
        <div class="header">
          <div class="header-bottom-area">
            <div class="container-fluid">
              <div class="header-menu-content">
                <nav class="navbar navbar-expand-lg p-0">
                  <Link class="site-logo site-title" to="/">
                    <img src={Logo} alt="انا حر" />
                  </Link>
                  <button
                    class="navbar-toggler ms-auto"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="fas fa-bars"></span>
                  </button>

                  <div
                    class="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul class="navbar-nav main-menu ms-auto me-auto">
                      <li>
                        <Link to="/" class="">
                          {t("ana7r-4")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/services"> {t("ana7r-5")}</Link>
                      </li>
                      <li>
                        <Link to="/products">{t("ana7r-6")}</Link>
                      </li>
                      <li>
                        <Link to="/ana7r-jobs">{t("ana7r-7")}</Link>
                      </li>
                      <li>
                        <Link to="/ana7r-blogs">{t("ana7r-8")}</Link>
                      </li>
                      <li>
                        <Link to="/contact">{t("ana7r-9")}</Link>
                      </li>
                    </ul>
                    <div class="language-select-area">
                      <div class="dropdown">
                        <button
                          class="btn btn-white dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ border: "1px solid gray" }}
                        >
                          <i class="fa-thin fa fa-globe"></i>&nbsp; &nbsp;
                          {t("ana7r-1")}
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={changeLang("en")}
                            >
                              {t("ana7r-2")}
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={changeLang("ara")}
                            >
                              {t("ana7r-3")}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="header-action">
                      <Link to="/login" class="btn--base active">
                        Sign In
                      </Link>
                      <Link to="/register-account" class="btn--base">
                        Sign Up
                      </Link>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <div class="header-short-menu">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12 mt-2 mb-0">
                  <ul class="short-menu">
                    <li class="short-menu-close-btn-area">
                      <button type="button" class="short-menu-close-btn">
                        {t("ana7r-10")}
                      </button>
                    </li>
                    <li>
                      <Link to="/service-category">{t("ana7r-11")}</Link>
                    </li>
                    <li>
                      <Link to="/service-category">{t("ana7r-12")}</Link>
                    </li>
                    <li>
                      <Link to="/service-category">{t("ana7r-13")}</Link>
                    </li>
                    <li>
                      <Link to="/service-category">{t("ana7r-14")}</Link>
                    </li>
                    <li>
                      <Link to="/service-category">{t("ana7r-15")}</Link>
                    </li>
                    <li>
                      <Link to="/service-category">{t("ana7r-10")}</Link>
                    </li>
                    <li>
                      <Link to="/service-category">{t("ana7r-16")}</Link>
                    </li>
                    <li>
                      <Link to="/service-category">{t("ana7r-17")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
