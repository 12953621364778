import React, { useEffect } from "react";
import { preLoaderAnim } from "../animations/";
import image1 from "../../assets/images/logoIcon/logo.png";
import "./preloader.css";

const PreLoader = () => {
  useEffect(() => {
    preLoaderAnim();
  }, []);
  return (
    <div className="preloader">
      <div className="texts-container">
        <span>Wellcome</span>
        <span> To</span>
        <span>Ana7r</span>
      </div>
    </div>
  );
};

export default PreLoader;
