import React from "react";
import "./blogdetail.css";
import image1 from "../../assets/images/user/profile/64a1c134531ce1688322356.jpg";
import Comment1 from "../../assets/images/user/profile/comments-1.jpg";
import Comment2 from "../../assets/images/user/profile/comments-2.jpg";
import Comment3 from "../../assets/images/user/profile/comments-3.jpg";
import Comment4 from "../../assets/images/user/profile/comments-4.jpg";
import Comment5 from "../../assets/images/user/profile/comments-5.jpg";
import ServicesSideBar from "../SideBarComponents/ServicesSideBar";

function BlogDetail() {
  return (
    <>
      <section id="blog" class="blog">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-lg-8 entries">
              <article class="entry entry-single">
                <div class="entry-img">
                  <img
                    src="https://source.unsplash.com/800x500/?Properties,Valuation"
                    alt=""
                    class="img-fluid"
                  />
                </div>

                <h2 class="entry-title">
                  <a href="blog-single.html">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Officia, quibusdam?
                  </a>
                </h2>

                <div class="entry-meta">
                  <ul>
                    <li class="d-flex align-items-center">
                      <i class="fa-regular fa fa-user"></i>
                      <a href="">Ahad </a>
                    </li>
                    <li class="d-flex align-items-center">
                      <i class="fa-regular fa fa-clock"></i>
                      <a href="">
                        <time datetime="2020-01-01">Jan 1, 2020</time>
                      </a>
                    </li>
                    <li class="d-flex align-items-center">
                      <i class="fa-solid fa fa-comment-dots"></i>
                      <a href="">12 Comments</a>
                    </li>
                  </ul>
                </div>

                <div class="entry-content">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Nostrum officiis voluptate fugit, cum, iure dolores quaerat
                    eius illo accusamus sequi ratione facilis quo corrupti
                    molestias quia voluptatem sint placeat, totam voluptas
                    praesentium. Animi, consequatur.
                  </p>

                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. At,
                    a?
                  </p>

                  <blockquote>
                    <p>
                      Et vero doloremque tempore voluptatem ratione vel aut.
                      Deleniti sunt animi aut. Aut eos aliquam doloribus minus
                      autem quos.
                    </p>
                  </blockquote>

                  <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Eum repellendus quae et placeat incidunt libero quos modi
                    quo necessitatibus perferendis, earum eaque cumque amet
                    illum debitis assumenda vel dignissimos adipisci repellat
                    reprehenderit.
                  </p>

                  <h3>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dicta,?
                  </h3>
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Culpa nulla modi dicta ab officia. Consequatur quaerat natus
                    voluptatibus perferendis repellat.
                  </p>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Dolore, mollitia.
                  </p>
                </div>

                <div class="entry-footer">
                  <i class="fa-regular fa fa-folder p-2"></i>
                  <ul class="cats">
                    <li>
                      <a href="#">Business</a>
                    </li>
                  </ul>

                  <i class="fa-solid fa fa-tag p-1"></i>
                  <ul class="tags">
                    <li>
                      <a href="#">Creative</a>
                    </li>
                    <li>
                      <a href="#">Tips</a>
                    </li>
                    <li>
                      <a href="#">Marketing</a>
                    </li>
                  </ul>
                </div>
              </article>
              {/* <!-- End blog entry --> */}

              {/* <!-- End blog author bio --> */}

              <div class="blog-comments">
                <h4 class="comments-count">8 Comments</h4>

                <div id="comment-1" class="comment">
                  <div class="d-flex">
                    <div class="comment-img">
                      <img src={Comment2} alt="" />
                    </div>
                    <div>
                      <h5>
                        <a href="">Malik Shahzad</a>
                        <a href="#" class="reply">
                          <i class="fa-solid fa fa-reply"></i> Reply
                        </a>
                      </h5>
                      <time datetime="2020-01-01">01 Jan, 2020</time>
                      <p>
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Atque iure reiciendis, voluptatem tempora
                        voluptatibus illo natus impedit ipsa itaque praesentium.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <!-- End comment #1 --> */}

                <div id="comment-2" class="comment">
                  <div class="d-flex">
                    <div class="comment-img">
                      <img src={Comment3} alt="" />
                    </div>
                    <div>
                      <h5>
                        <a href="">Malik Zohaib</a>
                        <a href="#" class="reply">
                          <i class="fa-solid fa fa-reply"></i> Reply
                        </a>
                      </h5>
                      <time datetime="2020-01-01">01 Jan, 2020</time>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Atque, minima cumque?
                      </p>
                    </div>
                  </div>

                  <div id="comment-reply-1" class="comment comment-reply">
                    <div class="d-flex">
                      <div class="comment-img">
                        <img src={Comment5} alt="" />
                      </div>
                      <div>
                        <h5>
                          <a href="">Ahad</a>
                          <a href="#" class="reply">
                            <i class="fa-solid fa fa-reply"></i> Reply
                          </a>
                        </h5>
                        <time datetime="2020-01-01">01 Jan, 2020</time>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Magnam, ut optio accusantium quam dicta nihil
                          enim sed explicabo, laboriosam veniam adipisci
                          pariatur! Animi, numquam recusandae. Laudantium beatae
                          id at voluptatem ipsa nihil?
                        </p>
                      </div>
                    </div>

                    <div id="comment-reply-2" class="comment comment-reply">
                      <div class="d-flex">
                        <div class="comment-img">
                          <img src={Comment2} alt="" />
                        </div>
                        <div>
                          <h5>
                            <a href="">Ahad</a>
                            <a href="#" class="reply">
                              <i class="fa-solid fa fa-reply"></i> Reply
                            </a>
                          </h5>
                          <time datetime="2020-01-01">01 Jan, 2020</time>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Sapiente a quae quaerat nulla quam dolorum
                            totam natus quis qui quia.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <!-- End comment reply #2--> */}
                  </div>
                  {/* <!-- End comment reply #1--> */}
                </div>
                {/* <!-- End comment #2--> */}

                <div id="comment-3" class="comment">
                  <div class="d-flex">
                    <div class="comment-img">
                      <img src={Comment4} alt="" />
                    </div>
                    <div>
                      <h5>
                        <a href="">Malik</a>
                        <a href="#" class="reply">
                          <i class="fa-solid fa fa-reply"></i> Reply
                        </a>
                      </h5>
                      <time datetime="2020-01-01">01 Jan, 2020</time>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Cum temporibus cupiditate nostrum reiciendis ducimus eos
                        cumque veritatis magnam, rerum autem.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <!-- End comment #3 --> */}

                <div id="comment-4" class="comment">
                  <div class="d-flex">
                    <div class="comment-img">
                      <img src={Comment5} alt="" />
                    </div>
                    <div>
                      <h5>
                        <a href="">Malik Mesum</a>
                        <a href="#" class="reply">
                          <i class="fa-solid fa fa-reply"></i> Reply
                        </a>
                      </h5>
                      <time datetime="2020-01-01">01 Jan, 2020</time>
                      <p>
                        Dolorem atque aut. Omnis doloremque blanditiis quia eum
                        porro quis ut velit tempore. Cumque sed quia ut maxime.
                        Est ad aut cum. Ut exercitationem non in fugiat.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <!-- End comment #4 --> */}

                <div class="reply-form">
                  <h4>Leave a Reply</h4>
                  <p>
                    Your email address will not be published. Required fields
                    are marked *
                  </p>
                  <form action="">
                    <div class="row">
                      <div class="col-md-6 form-group">
                        <input
                          name="name"
                          type="text"
                          class="form-control"
                          placeholder="Your Name*"
                        />
                      </div>
                      <div class="col-md-6 form-group">
                        <input
                          name="email"
                          type="text"
                          class="form-control"
                          placeholder="Your Email*"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col form-group">
                        <input
                          name="website"
                          type="text"
                          class="form-control"
                          placeholder="Your Website"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col form-group">
                        <textarea
                          name="comment"
                          class="form-control"
                          placeholder="Your Comment*"
                        ></textarea>
                      </div>
                    </div>
                    <button type="submit" class="btn btn-primary">
                      Post Comment
                    </button>
                  </form>
                </div>
              </div>
              {/* <!-- End blog comments --> */}
            </div>
            {/* <!-- End blog entries list --> */}

            <div class="col-lg-4">
              <div class="sidebar my-side-bar">
                <h3 class="sidebar-title">Search</h3>
                <div class="sidebar-item search-form">
                  <form action="">
                    <input type="text" />
                    <button type="submit">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </form>
                </div>
                {/* <!-- End sidebar search formn--> */}

                <h3 class="sidebar-title">Categories</h3>
                <div class="sidebar-item categories">
                  <ul>
                    <li>
                      <a href="#">
                        General <span>(25)</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Lifestyle <span>(12)</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Travel <span>(5)</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Design <span>(22)</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Creative <span>(8)</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Educaion <span>(14)</span>
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <!-- End sidebar categories--> */}
                {/* <h3 class="sidebar-title">Recent Posts</h3> */}
                <ServicesSideBar />
                {/* <!-- End sidebar recent posts--> */}

                <h3 class="sidebar-title">Tags</h3>
                <div class="sidebar-item tags">
                  <ul>
                    <li>
                      <a href="#">App</a>
                    </li>
                    <li>
                      <a href="#">IT</a>
                    </li>
                    <li>
                      <a href="#">Business</a>
                    </li>
                    <li>
                      <a href="#">Mac</a>
                    </li>
                    <li>
                      <a href="#">Design</a>
                    </li>
                    <li>
                      <a href="#">Office</a>
                    </li>
                    <li>
                      <a href="#">Creative</a>
                    </li>
                    <li>
                      <a href="#">Studio</a>
                    </li>
                    <li>
                      <a href="#">Smart</a>
                    </li>
                    <li>
                      <a href="#">Tips</a>
                    </li>
                    <li>
                      <a href="#">Marketing</a>
                    </li>
                  </ul>
                </div>
                {/* <!-- End sidebar tags--> */}
              </div>
              {/* <!-- End sidebar --> */}
            </div>
            {/* <!-- End blog sidebar --> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogDetail;
