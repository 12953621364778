import React from "react";
import image1 from "../../../assets/images/user/profile/649c93978eca21687982999.png";
import image2 from "../../../assets/images/user/profile/64a1c54d5b70f1688323405.jpg";
import { Link } from "react-router-dom";

function DetailSideBar() {
  return (
    <>
      <div className="widget my-side-bar">
        <div className="profile-widget">
          <div className="profile-widget-header">
            <div className="profile-widget-thumb">
              <img src={image1} alt="User image" />
            </div>
            <div className="profile-widget-author">
              <div className="thumb">
                <img src={image2} alt="" />
              </div>
              <div className="content">
                <h4 className="name">
                  {" "}
                  <Link to="/user"></Link>Zohaib Hassan
                </h4>
                <span className="designation">Softwere</span>
              </div>
            </div>
            <div className="profile-widget-author-meta mb-10-none">
              <div className="location mb-10">
                <i className="fas fa-map-marker-alt"></i>
                <span>Pakistan</span>
              </div>
              <div className="btn-area mb-10">
                <Link to="/user" class="btn--base">
                  View Profile
                </Link>
              </div>
            </div>
          </div>
          <div className="profile-list-area">
            <ul className="details-list">
              <li>
                <span>Total Service</span>
                <span>4</span>
              </li>
              <li>
                <span>In Progress</span>
                <span>4</span>
              </li>
              <li>
                <span>Rating</span>
                <span className="ratings">
                  <i className="las la-star text--warning"></i> (4.67)
                </span>
              </li>
              <li>
                <span>Member Since</span>
                <span>28 Jun 2023</span>
              </li>
              <li>
                <span>Verified User</span>
                <span className="text--success">Yes</span>
              </li>
            </ul>
            <div className="widget-btn mt-20">
              <Link to="/user" class="btn--base w-100">
                Hire Me
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailSideBar;
