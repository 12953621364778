import React from "react";
import image1 from "../../assets/images/frontend/blog/thumb_64a231bc4935b1688351164.png";
import image2 from "../../assets/images/frontend/blog/thumb_64a24a2ac42331688357418.png";
import image3 from "../../assets/images/frontend/blog/thumb_64a7d9b55fad81688721845.png";
import image4 from "../../assets/images/frontend/blog/thumb_64a7dde27a5131688722914.png";
import image5 from "../../assets/images/frontend/blog/thumb_64a7e9eb22ae21688725995.png";
import image6 from "../../assets/images/frontend/blog/thumb_64a7eb1cba47d1688726300.png";
import image7 from "../../assets/images/frontend/blog/thumb_64a969ebae9401688824299.png";
import image8 from "../../assets/images/frontend/blog/thumb_64a96a7ae0fee1688824442.png";
import image9 from "../../assets/images/frontend/blog/thumb_64a96ad8ca9db1688824536.png";
import image10 from "../../assets/images/frontend/blog/thumb_64ad4445f3ccf1689076805.png";
import { Link } from "react-router-dom";
import "./blogcard.css";

function BlogCard() {
  const blogdata = [
    {
      image: image1,
      postdate: "Tue, September 11",
      posttitle: "Pakistan Win The orld Cup",
    },
    {
      image: image2,
      postdate: "Tue, September 15",
      posttitle: "India Win The World Cup",
    },
    {
      image: image3,
      postdate: "Tue, September 15",
      posttitle: "South Africa Win The World Cup",
    },
    {
      image: image4,
      postdate: "Tue, September 15",
      posttitle: "Nasa Space ",
    },
    {
      image: image5,
      postdate: "Tue, September 15",
      posttitle: "Job For React js Front End Developer",
    },
    {
      image: image6,
      postdate: "Tue, September 15",
      posttitle: "New Job For Laraval Developer",
    },
    {
      image: image7,
      postdate: "Tue, September 15",
      posttitle: "New Job For Laraval Developer",
    },
    {
      image: image8,
      postdate: "Tue, September 15",
      posttitle: "New Job For Laraval Developer",
    },
    {
      image: image9,
      postdate: "Tue, September 15",
      posttitle: "New Job For Laraval Developer",
    },
    {
      image: image10,
      postdate: "Tue, September 15",
      posttitle: "New Job For Laraval Developer",
    },
  ];

  return (
    <>
      <section id="recent-blog-posts" class="recent-blog-posts">
        <div class="container-fluid" data-aos="fade-up">
          <header class="section-header">
            <h2>Blog</h2>
            <p>Recent Blogs Posts</p>
          </header>
          <div class="row g-4">
            {blogdata.map((data) => (
              <div class="col-lg-3 ">
                <div class="post-box">
                  <div class="post-img" >
                    <img src={data.image} class="img-fluid" alt="" />
                  </div>
                  <span class="post-date">{data.postdate}</span>
                  <h3 class="post-title">{data.posttitle}</h3>
                  <Link
                    to="/blog-detail"
                    class="readmore stretched-link mt-auto"
                  >
                    <span>Read More</span>
                    <i class="fa-solid fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div class="col-12 text-center wow fadeInUp mt-4" data-wow-delay="0.1s">
        <Link to="/blog-detail">
          <a class="btn--base  py-3 px-5">Browse More Blogs</a>
        </Link>
      </div>
    </>
  );
}

export default BlogCard;
