import React from "react";
import SideBarProfie from "../SideBarprofile/SideBarProfie";
import UserDetailSection from "../UserDetailSection/UserDetailSection";
import ReviewSection from "../ReviewSection/ReviewSection";

function UserProfileDetail() {
  return (
    <>
      <section class="all-sections pt-60">
        <div class="container-fluid p-max-sm-0">
          <div class="sections-wrapper d-flex flex-wrap justify-content-center">
            <article class="main-section">
              <div class="section-inner">
                <div class="item-section item-overview-section">
                  <div class="container-fluid">
                    <div class="row justify-content-center mb-30-none">
                      <SideBarProfie />
                      <UserDetailSection />
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
    </>
  );
}

export default UserProfileDetail;
