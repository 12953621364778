import React, { useState } from "react";
import axios from "axios";
import "./otp.css";
import image1 from "../../assets/images/logoIcon/logo.png";
import { Link } from "react-router-dom";

function Otp() {
  const [otp1, setotp1] = useState(" ");
  const [otp2, setotp2] = useState(" ");
  const [otp3, setotp3] = useState(" ");
  const [otp4, setotp4] = useState(" ");

  function handleDate(e) {
    e.preventDefault();
    //  API post method
    axios
      .post("https://jsonplaceholder.typicode.com/users", {
        otp1,
        otp2,
        otp3,
        otp4,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <section className="otp">
        <div class="container">
          <div class="row">
            <div class="col-sm-9 col-md-7 col-lg-7 mx-auto">
              <div class="card border-0 shadow rounded-3 my-5">
                <div class="card-body p-4 p-sm-5">
                  <div className="olxlog text-center">
                    <img src={image1} alt="" />
                  </div>
                  <h5 class="ana7r-card-title text-center mt-4 ">
                    Welcome to ana7r <br /> <br />
                  </h5>
                  <form onSubmit={handleDate}>
                    <div class="d-flex justify-content-center align-items-center container">
                      <div class="otpcard py-5 px-4">
                        <h5 class="m-0">Email verification</h5>
                        <span class="mobile-text" />
                        Enter the code we just send on your
                        <b class="text-danger"> Email address</b>
                        <div class="d-flex flex-row mt-2">
                          <input
                            type="text"
                            class="form-control my-form-control"
                            onChange={(e) => setotp1(e.target.value)}
                            autofocus=""
                          />
                          <input
                            type="text"
                            class="form-control my-form-control"
                            onChange={(e) => setotp2(e.target.value)}
                          />
                          <input
                            type="text"
                            class="form-control my-form-control"
                            onChange={(e) => setotp3(e.target.value)}
                          />
                          <input
                            type="text"
                            class="form-control my-form-control"
                            onChange={(e) => setotp4(e.target.value)}
                          />
                        </div>
                        <div class="text-center mt-5">
                          <span class="d-block mobile-text">
                            Don't receive the code?
                          </span>
                          <span class="font-weight-bold text-danger cursor">
                            Resend
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="d-grid text-center">
                      <Link to="/confirm-password">
                        <button
                          class="btn  btn-login  fw-bold w-100"
                          type="submit"
                        >
                          Confirm Otp
                        </button>
                      </Link>
                    </div>
                    <hr class="my-4" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Otp;
