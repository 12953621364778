import React from "react";

function AddvertisementButton() {
  return (
    <aside class="aside-bar left-aside">
      <div class="aside-inner">
        <div class="add-item slot-1 mb-30">
          <div class="add-header text-center">
            <h3 class="title">ADDVERTISEMENT</h3>
          </div>
          <div class="dynamicScript" data-id=""></div>
        </div>
      </div>
    </aside>
  );
}

export default AddvertisementButton;
