import React from "react";
import ImageGallery from "../ImageGallery/ImageGallery";
import DetailSideBar from "./DetailSideBar/DetailSideBar";
import { Link } from "react-router-dom";
import ItemCard from "../ItemCard/ItemCard";
import ProductCard from "../ProductCard/ProductCard";

function GigDetail() {
  return (
    <>
      <section class="all-sections pt-60">
        <div class="container-fluid p-max-sm-0">
          <div class="sections-wrapper d-flex flex-wrap justify-content-center">
            <article class="main-section">
              <div class="section-inner">
                <div class="item-section item-overview-section">
                  <div class="container-fluid">
                    <div class="row justify-content-center mb-30-none">
                      <div className="col-xl-9 col-lg-9 mb-30">
                        <div className="item-details-area">
                          <div className="item-details-box">
                            <div className="item-details-thumb-area">
                              <div className="item-details-slider-area">
                                <ImageGallery />
                                <div className="item-details-content">
                                  <h3 className="title">
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipisicing elit. Placeat, cumque?
                                  </h3>
                                  <div className="item-details-footer mb-20-none">
                                    <div className="left mb-20">
                                      <a
                                        href="javascript:void(0)"
                                        class="item-love me-2 loveHeartAction"
                                        data-serviceid="72"
                                      >
                                        <i class="fas fa-heart"></i>
                                        <span class="give-love-amount">()</span>
                                      </a>
                                    </div>
                                    <div className="right mb-20">
                                      <div className="social-area">
                                        <ul className="footer-social">
                                          <li>
                                            <a href="" target="__blank">
                                              <i class="fab fa-facebook-f"></i>
                                            </a>
                                          </li>
                                          <li>
                                            <a href="" target="__blank">
                                              <i class="fab fa-twitter"></i>
                                            </a>
                                          </li>
                                          <li>
                                            <a href="" target="__blank">
                                              <i class="fab fa-linkedin-in"></i>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="product-tab mt-40">
                            <nav>
                              <div
                                class="nav nav-tabs"
                                id="nav-tab"
                                role="tablist"
                              >
                                <button
                                  class="nav-link active"
                                  id="des-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#des"
                                  type="button"
                                  role="tab"
                                  aria-controls="des"
                                  aria-selected="true"
                                >
                                  Description
                                </button>
                                <button
                                  class="nav-link"
                                  id="review-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#review"
                                  type="button"
                                  role="tab"
                                  aria-controls="review"
                                  aria-selected="false"
                                >
                                  Reviews (0)
                                </button>
                                <button
                                  class="nav-link"
                                  id="comment-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#comment"
                                  type="button"
                                  role="tab"
                                  aria-controls="comment"
                                  aria-selected="false"
                                >
                                  Buyer Comments
                                </button>
                              </div>
                            </nav>

                            <div className="tab-content" id="nav-tabContent">
                              <div className="tab-pane fade active show">
                                <div className="product-desc-content">
                                  <span>
                                    Lorem ipsum, dolor sit amet consectetur
                                    adipisicing elit. Soluta consequuntur
                                    suscipit quam? Lorem ipsum dolor sit, amet
                                    consectetur adipisicing elit. Quod,
                                    similique?
                                  </span>
                                  <br />
                                  <span>
                                    Lorem ipsum dolor sit amet consectetur,
                                    adipisicing elit. Exercitationem dicta
                                    mollitia atque, cum distinctio maxime soluta
                                    ipsum accusamus quia libero!
                                  </span>
                                  <br />
                                  <span>
                                    Lorem ipsum dolor sit.lorem2 Lorem, ipsum.{" "}
                                  </span>
                                  <br />
                                  Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Quis ducimus atque quas ea
                                  tempore sit architecto? Eos, maiores impedit.
                                  Adipisci, molestiae.
                                </div>
                                <div className="item-details-tag">
                                  <ul className="tags-wrapper">
                                    <li className="caption">tags</li>
                                    <li className="caption">
                                      <Link to="/user">Psd file layers</Link>
                                    </li>
                                    <li className="caption">
                                      <Link to="/user">Psd file layers</Link>
                                    </li>
                                    <li className="caption">
                                      <Link to="/user">Psd file layers</Link>
                                    </li>
                                    <li className="caption">
                                      <Link to="/user">Psd file layers</Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              {/* review Section  */}
                              <div
                                class="tab-pane fade active show"
                                id="review"
                                role="tabpanel"
                                aria-labelledby="review-tab"
                              >
                                <div class="product-reviews-content">
                                  <div class="item-review-widget-wrapper">
                                    <div class="left">
                                      <h2 class="title text-white">0.00</h2>
                                      <div class="ratings"></div>
                                      <span class="sub-title text-white">
                                        0 reviews
                                      </span>
                                    </div>
                                    <div class="right">
                                      <ul class="list">
                                        <li>
                                          <span class="caption">
                                            <i class="fas fa-thumbs-up text--success"></i>
                                            Total Likes{" "}
                                          </span>
                                          <span class="value">0</span>
                                        </li>
                                        <li>
                                          <span class="caption">
                                            <i class="fas fa-thumbs-down text--danger"></i>
                                            Total Dislikes{" "}
                                          </span>
                                          <span class="value">0</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="col-xl-12">
                                      <h3 class="reviews-title">0 reviews</h3>
                                      <ul
                                        class="comment-list"
                                        id="reviewShow"
                                      ></ul>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* review Section End  */}
                            </div>
                            <div
                              class="tab-pane fade active show"
                              id="comment"
                              role="tabpanel"
                              aria-labelledby="comment-tab"
                            >
                              <div class="product-reviews-content product-comment-content">
                                <div class="comment-form-area mb-40">
                                  <form
                                    class="comment-form"
                                    method="POST"
                                    action="https://ana7r.com/comment"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      value="joBYIysua8Re7eoLFKh7fkeZQQdTL1uwgMoFhacn"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      value="72"
                                      name="service_id"
                                    />
                                    <textarea
                                      class="form-control h-auto"
                                      name="comment"
                                      placeholder="Write Comment"
                                      rows="8"
                                      required=""
                                    ></textarea>
                                    <button
                                      type="submit"
                                      class="submit-btn mt-20"
                                    >
                                      Post Comment
                                    </button>
                                  </form>
                                </div>
                                <div class="row">
                                  <div class="col-xl-12">
                                    <h3 class="reviews-title">0 comments</h3>
                                    <ul
                                      class="comment-list"
                                      id="commentShow"
                                    ></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="service-card mt-40">
                              <div class="service-card-header bg--gray text-center">
                                <h4 class="title">Extra Services</h4>
                              </div>
                              <div class="service-card-body">
                                <div class="service-card-form">
                                  <div class="form-row">
                                    <div class="left">
                                      <div class="form-group custom-check-group">
                                        <label for="level-1">
                                          Design 10 posts
                                        </label>
                                      </div>
                                    </div>
                                    <div class="right">
                                      <span class="value">$20.00</span>
                                    </div>
                                  </div>
                                  <div class="service-card-btn">
                                    <Link to="/user" class="btn--base">
                                      Order Now ($5.00)
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* other Services Section here  */}

                      <div className="col-xl-3 col-lg-3 mb-30">
                        <div className="sidebar ">
                          <div className="widget custom-widget mb-30">
                            <h3 className="widget-title">SHORT DETAILS</h3>
                            <ul className="details-list">
                              <li>
                                <span>Delivery Time</span>
                                <span>1 Days</span>
                              </li>
                              <li>
                                <span>Service Price</span>
                                <span>5.00 USD</span>
                              </li>
                            </ul>
                            <div className="widget-btn mt-20">
                              <a href="" class="btn--base w-100">
                                Order Now ($5.00)
                              </a>
                            </div>
                          </div>
                          <div className="widget custom-widget text-center mb-30">
                            <h3 className="widget-title">
                              Do you have any special requirements?
                            </h3>
                            <div className="widget-btn mt-20">
                              <a href="" class="btn--base w-100">
                                Contact Now
                              </a>
                            </div>
                          </div>
                          <DetailSideBar />
                        </div>
                      </div>
                      <div class="item-bottom-area pt-80">
                        <div class="row justify-content-center">
                          <div class="col-xl-12 ">
                            <div class="section-header">
                              <h2 class="section-title">
                                Other services by Malik Zohaib
                              </h2>
                            </div>
                          </div>

                          <div class="item-card-wrapper border-0 p-0 grid-view">
                            <ItemCard />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
    </>
  );
}

export default GigDetail;
