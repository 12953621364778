import React from "react";
import image1 from "../../assets/images/job/64a0cd373e22d1688259895.png";
import image2 from "../../assets/images/job/64a1c6cfe3a3f1688323791.png";
import image3 from "../../assets/images/job/64a1b8d36401f1688320211.png";
import image4 from "../../assets/images/job/64a1bd89258941688321417.png";
import image5 from "../../assets/images/job/64a1bdb19d72c1688321457.png";
import image6 from "../../assets/images/job/64a1bddd169ce1688321501.png";
import image7 from "../../assets/images/job/64a1c0791b9991688322169.png";
import image8 from "../../assets/images/job/64a1c0a10c5c41688322209.png";
import image9 from "../../assets/images/job/64a1c0ca6c6741688322250.png";
import image10 from "../../assets/images/job/64a1c306ae91c1688322822.png";
import image11 from "../../assets/images/job/64a1c324839d31688322852.png";
import image12 from "../../assets/images/job/64a1c33cbff841688322876.png";
import image13 from "../../assets/images/job/64a1c49d2819c1688323229.png";
import image14 from "../../assets/images/job/64a1c4cf8b94e1688323279.png";
import image15 from "../../assets/images/job/64a1c809036b81688324105.png";
import Userimage1 from "../../assets/images/user/profile/64be73575100d1690202967.jpg";
import { Link } from "react-router-dom";

function JobListCard() {
  const jobdata = [
    {
      name: "MALIK ZOHAIB",
      amount: "$100.00",
      level: "LV 4",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: "Create A website React js With backend Laraval",
      day: "1 day",
      image: image1,
      userimage: "",
    },
    {
      name: "MALIK sohaib",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: "Create A website React js With backend Laraval",
      day: "5 day",
      image: image2,
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: image3,
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: image4,
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: image5,
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: image6,
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: image7,
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: image8,
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: image9,
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: image10,
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: image11,
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: image12,
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: image13,
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: image14,
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: image15,
      userimage: "",
    },
  ];
  return (
    <>
      <div className="item-card-wrapper list-view">
        {jobdata.map((data) => (
          <div class="item-card">
            <div class="item-card-thumb">
              <img src={data.image} alt="Job Image" />
            </div>
            <div class="item-card-content">
              <div class="item-card-content-top">
                <div class="left">
                  <div class="author-thumb">
                    <img src={Userimage1} alt="author" />
                  </div>
                  <div class="author-content">
                    <h5 class="name">
                      <Link to="/">{data.name}</Link>
                      <span class="level-text">{data.level}</span>
                    </h5>
                  </div>
                </div>
                <div class="right">
                  <div class="item-amount">{data.amount}</div>
                </div>
              </div>
              <div class="item-tags order-3">
                <Link to="/" style={{ textDecoration: "none" }}>
                  {data.tag1}
                </Link>
                <Link to="/" style={{ textDecoration: "none" }}>
                  {data.tag2}
                </Link>
                <Link to="/" style={{ textDecoration: "none" }}>
                  {data.tag3}
                </Link>
              </div>
              <h3 class="item-card-title">
                <Link to="/">{data.title}</Link>
              </h3>
            </div>
            <div class="item-card-footer mb-10-none ">
              <div class="left mb-10 ">
                <Link
                  to="/"
                  class="btn--base active date-btn"
                  style={{ marginLeft: "2px" }}
                >
                  {data.day}
                </Link>
                <Link to="/" class="btn--base bid-btn ">
                  Total Bids(4)
                </Link>
              </div>
              <div class="right mb-10">
                <div class="order-btn">
                  <Link to="/" class="btn--base">
                    <i class="las la-shopping-cart"></i>
                    Bid Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default JobListCard;
