import React from "react";
import ViewListBtn from "../ShortingComponents/ViewListBtn";
import Search from "../ShortingComponents/Search";
import CategoriesFilter from "../SideBarComponents/CategoriesFilter";
import LevelCheckBox from "../SideBarComponents/LevelCheckBox/LevelCheckBox";
import AddvertisementButton from "../SideBarComponents/AddvertisementButton";
import ServicesSideBar from "../SideBarComponents/ServicesSideBar";
import ShowMoreButton from "../SideBarComponents/ShowMoreButton";
import Pagination from "../ShortingComponents/Pagination";
import JobCard from "../JobCard/JobCard";

function JobsCard2() {
  return (
    <>
      <section className="all-sections pt-60">
        <div className="container-fluid p-max-sm-0">
          <div className="sections-wrapper d-flex flex-wrap justify-content-center">
            <article className="main-section">
              <div className="section-inner">
                <div className="item-section">
                  <div className="container-fluid">
                    <div className="item-top-area d-flex flex-wrap justify-content-between align-items-center">
                      <div className="item-wrapper d-flex flex-wrap justify-content-between     align-items-center float-end">
                        <div className="item-wrapper-left d-flex flex-wrap align-items-center ">
                          <div className="item-value my-2 mb-2">
                            <span className="mt-3 mb-3">
                              Showing item &nbsp;
                              <span className="result">1 of 21</span>
                            </span>
                          </div>
                        </div>
                        <ViewListBtn />
                      </div>
                      <Search />
                    </div>
                    <div className="item-bottom-area">
                      <div className="row justify-content-center mb-30-none">
                        <div className="col-xl-3 col-lg-3 mb-30">
                          <div
                            className="sidebar"
                            style={{
                              borderRight: "1px solid #e1e7ec",
                            }}
                          >
                            <CategoriesFilter />
                            <LevelCheckBox />
                            <AddvertisementButton />
                            <ServicesSideBar />
                            <ShowMoreButton />
                            <AddvertisementButton />
                          </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 mb-30">
                          <div className="item-card-wrapper grid-view">
                            <JobCard />
                          </div>
                        </div>
                        <Pagination />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
    </>
  );
}

export default JobsCard2;
