import React from "react";
import image1 from "../../assets/images/user/profile/64a1c1346e2381688322356.jpg";
import image2 from "../../assets/images/user/profile/64a1c134531ce1688322356.jpg";

function SideBarProfie() {
  return (
    <>
      <div class="col-xl-3 col-lg-3 mb-30 Side-Bar">
        <div class="sidebar">
          <div class="widget mb-30">
            <div class="profile-widget">
              <div class="profile-widget-header">
                <div class="profile-widget-thumb">
                  <img src={image1} />
                  <span class="badge-offline bg--warning">Ofline</span>
                  <a href="">
                    <span class="badge-follow bg--info">Follow</span>
                  </a>
                </div>
                <div class="profile-widget-author">
                  <div class="thumb">
                    <img src={image2} alt="ahmedbary" />
                  </div>
                  <div class="content">
                    <h4 class="name">
                      <a href="ahmedbary.html">Malik Zohaib</a>
                    </h4>
                    <span class="designation">Softwere Eng</span>
                    <div class="ratings">
                      <span>
                        <i class="las la-star text--warning"></i>0 (0 reviews)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="profile-list-area">
                <ul class="details-list">
                  <li>
                    <span>Total Service</span> <span>3</span>
                  </li>
                  <li>
                    <span>Work Complete</span> <span>0</span>
                  </li>
                  <li>
                    <span>Total Software</span> <span>3</span>
                  </li>
                  <li>
                    <span>Work Pending</span> <span>0</span>
                  </li>
                  <li>
                    <span>Total job</span> <span>3</span>
                  </li>
                  <li>
                    <span>Country</span> <span>Egypt</span>
                  </li>
                  <li>
                    <span>Member since</span>
                    <span>02 July 2023</span>
                  </li>
                  <li>
                    <span>VerifiedUser</span>
                    <span class="text--success">Yes</span>
                  </li>
                </ul>
                <div class="widget-btn mt-20">
                  <a href="../login.html" class="btn--base w-100">
                    Contact Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="widget custom-widget mb-30">
            <h3 class="widget-title">ABOUTME</h3>
            <p>Software Engineer</p>
          </div>
          <div class="widget custom-widget">
            <div class="follow-tab">
              <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    class="nav-link active"
                    id="followers-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#followers"
                    type="button"
                    role="tab"
                    aria-controls="followers"
                    aria-selected="true"
                  >
                    Followers (550)
                  </button>
                  <button
                    class="nav-link"
                    id="following-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#following"
                    type="button"
                    role="tab"
                    aria-controls="following"
                    aria-selected="false"
                  >
                    Following (0)
                  </button>
                </div>
              </nav>
              <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="followers"
                  role="tabpanel"
                  aria-labelledby="followers-tab"
                ></div>
                <div
                  class="tab-pane fade"
                  id="following"
                  role="tabpanel"
                  aria-labelledby="following-tab"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBarProfie;
