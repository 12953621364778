import React from "react";
import image1 from "../../assets/images/job/64a1bdb19d72c1688321457.png";
import Userimage1 from "../../assets/images/user/profile/64be73575100d1690202967.jpg";
import { Link } from "react-router-dom";

function JobCard() {
  const jobdata = [
    {
      name: "MALIK ZOHAIB",
      amount: "$100.00",
      level: "LV 4",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: "Create A website React js With backend Laraval",
      day: "1 day",
      image: "",
      userimage: "",
    },
    {
      name: "MALIK sohaib",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: "Create A website React js With backend Laraval",
      day: "5 day",
      image: "",
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: "",
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: "",
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: "",
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: "",
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: "",
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: "",
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: "",
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: "",
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: "",
      userimage: "",
    },
    {
      name: "MALIK Shahzad",
      amount: "$100.00",
      level: "LV 2",
      tag1: "ui ux",
      tag2: "ui ux designer",
      tag3: "graphic designer",
      title: " React js With backend Laraval",
      day: "19 day",
      image: "",
      userimage: "",
    },
  ];

  return (
    <>
      {jobdata.map((data) => (
        <div class="item-card">
          <div class="item-card-thumb">
            <img src={image1} alt="Job Image" />
          </div>
          <div class="item-card-content">
            <div class="item-card-content-top">
              <div class="left">
                <div class="author-thumb">
                  <img src={Userimage1} alt="author" />
                </div>
                <div class="author-content">
                  <h5 class="name">
                    <Link to="/">{data.name}</Link>
                    <span class="level-text">{data.level}</span>
                  </h5>
                </div>
              </div>
              <div class="right">
                <div class="item-amount">{data.amount}</div>
              </div>
            </div>
            <div class="item-tags order-3">
              <Link to="/" style={{ textDecoration: "none" }}>
                {data.tag1}
              </Link>
              <Link to="/" style={{ textDecoration: "none" }}>
                {data.tag2}
              </Link>
              <Link to="/" style={{ textDecoration: "none" }}>
                {data.tag3}
              </Link>
            </div>
            <h3 class="item-card-title">
              <Link to="/">{data.title}</Link>
            </h3>
          </div>
          <div class="item-card-footer mb-10-none ">
            <div class="left mb-10 ">
              <Link
                to="/"
                class="btn--base active date-btn"
                style={{ marginLeft: "2px" }}
              >
                {data.day}
              </Link>
              <Link to="/" class="btn--base bid-btn ">
                Total Bids(4)
              </Link>
            </div>
            <div class="right mb-10">
              <div class="order-btn">
                <Link to="/" class="btn--base">
                  <i class="las la-shopping-cart"></i>
                  Bid Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default JobCard;
