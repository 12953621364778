import React from "react";
import image1 from "../../assets/images/trendingslider/image1.webp";
import image2 from "../../assets/images/trendingslider/image2.webp";
import image3 from "../../assets/images/trendingslider/image3.webp";
import image4 from "../../assets/images/trendingslider/image4.webp";
import image5 from "../../assets/images/trendingslider/image5.webp";
import image6 from "../../assets/images/trendingslider/image6.webp";
import image7 from "../../assets/images/trendingslider/image7.webp";
import image8 from "../../assets/images/trendingslider/image8.webp";
import "./trendingslider.css";
import tEn from "../../locales/En/translation.json";
import tara from "../../locales/Ara/translation.json";
import { createRoot } from "react-dom/client";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: tEn,
    },
    ara: {
      translation: tara,
    },
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});
function TrendingSlider() {
  const { t } = useTranslation();
  return (
    <>
      <section
        className="trending-slider py-2 my-4 p-4 "
        style={{ backgroundColor: " white" }}
      >
        <div className="trending-title">
          <h2
            className="ms-4 mb-2 "
            style={{ fontSize: "35px", lineHeight: "130%", fontWeight: "700" }}
          >
            {t("ana7r-30")}
          </h2>
        </div>

        <div className="container-fluid mb-4 mt-4">
          <div
            id="carouselExampleControls"
            class="carousel carousel-dark slide "
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  <div class="col-lg-3">
                    <div class="card trending-image" style={{ width: "100%" }}>
                      <img src={image1} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h5 class="card-title">{t("ana7r-12")}</h5>
                        {/* <p class="card-text">by Zohiab Hassan</p> */}
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="card trending-image" style={{ width: "100%" }}>
                      <img src={image2} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h5 class="card-title">{t("ana7r-16")}</h5>
                        {/* <p class="card-text">by Zohiab Hassan</p> */}
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="card trending-image" style={{ width: "100%" }}>
                      <img src={image3} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h5 class="card-title">{t("ana7r-13")}</h5>
                        {/* <p class="card-text">by Zohiab Hassan</p> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="card trending-image" style={{ width: "100%" }}>
                      <img src={image7} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h5 class="card-title">{t("ana7r-17")}</h5>
                        {/* <p class="card-text">by Zohiab Hassan</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-lg-3">
                    <div class="card trending-image" style={{ width: "100%" }}>
                      <img src={image4} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h5 class="card-title">{t("ana7r-31")}</h5>
                        {/* <p class="card-text">by Zohiab Hassan</p> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="card trending-image" style={{ width: "100%" }}>
                      <img src={image8} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h5 class="card-title">{t("ana7r-32")}</h5>
                        {/* <p class="card-text">by Zohiab Hassan</p> */}
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="card trending-image" style={{ width: "100%" }}>
                      <img src={image5} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h5 class="card-title">{t("ana7r-33")}</h5>
                        {/* <p class="card-text">by Zohiab Hassan</p> */}
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="card trending-image" style={{ width: "100%" }}>
                      <img src={image6} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h5 class="card-title"> {t("ana7r-34")}</h5>
                        {/* <p class="card-text">by Zohiab Hassan</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default TrendingSlider;
