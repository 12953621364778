import React from "react";

function Pagination() {
  return (
    <>
      <nav>
        <nav>
          <ul class="pagination">
            <li
              class="page-item disabled"
              aria-disabled="true"
              aria-label="&laquo; Previous"
            >
              <span class="page-link" aria-hidden="true">
                &lsaquo;
              </span>
            </li>

            <li class="page-item active" aria-current="page">
              <span class="page-link">1</span>
            </li>
            <li class="page-item">
              <a class="page-link" href="">
                2
              </a>
            </li>

            <li class="page-item">
              <a class="page-link" href="" rel="next" aria-label="Next &raquo;">
                &rsaquo;
              </a>
            </li>
          </ul>
        </nav>
      </nav>
    </>
  );
}

export default Pagination;
