import React, { useState } from "react";
import axios from "axios";
import tEn from "../../locales/En/translation.json";
import tara from "../../locales/Ara/translation.json";
import { createRoot } from "react-dom/client";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: tEn,
    },
    ara: {
      translation: tara,
    },
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

function ContectUs() {
  const { t } = useTranslation();

  const [username, setUserName] = useState(" ");
  const [email, setemail] = useState(" ");
  const [subject, setsubject] = useState(" ");
  const [massage, setmassage] = useState(" ");

  function handleDate(e) {
    e.preventDefault();

    //  API post method

    axios
      .post("https://jsonplaceholder.typicode.com/users", {
        username,
        email,
        subject,
        massage,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <section className="contact-section pt-60 ptb-60 ">
        <div className="container">
          <div className="contact-wrapper">
            <div className="contact-area ">
              <div className="row justify-content-center m-0">
                <div className="col-xl-5 col-lg-6 p-0">
                  <div className="contact-info-item-area">
                    <div className="contact-info-item-inner mb-30-none">
                      <div className="contact-info-header mb-30">
                        <h3 className="header-title"> {t("ana7r-60")} </h3>
                      </div>
                      <div className="contact-info-item d-flex flex-wrap align-items-center mb-40">
                        <div className="contact-info-icon">
                          <i className="fas fa fa-map-marker-alt"></i>
                        </div>
                        <div className="contact-info-content">
                          <h3 className="title">{t("ana7r-61")}</h3>
                          <p>{t("ana7r-62")}</p>
                        </div>
                      </div>
                      <div className="contact-info-item d-flex flex-wrap align-items-center mb-40">
                        <div className="contact-info-icon">
                          <i className="fas fa-envelope"></i>
                        </div>
                        <div className="contact-info-content">
                          <h3 className="title">{t("ana7r-63")}</h3>
                          <p>{t("ana7r-63")}@ana7r.com</p>
                        </div>
                      </div>
                      <div className="contact-info-item d-flex flex-wrap align-items-center mb-40">
                        <div className="contact-info-icon">
                          <i className="fas fa-phone-alt"></i>
                        </div>
                        <div className="contact-info-content">
                          <h3 className="title">{t("ana7r-65")}</h3>
                          <p>+0201022531354</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 p-0 ">
                  <div
                    className="contact-form-area bg-white "
                    style={{
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  >
                    <h3 className="title">{t("ana7r-66")}</h3>
                    <p></p>
                    <form className="contact-form" onSubmit={handleDate}>
                      <input type="hidden" />{" "}
                      <div className="row justify-content-center mb-10-none">
                        <div className="col-lg-6 col-md-6 form-group">
                          <input
                            type="text"
                            name="name"
                            className="form--control"
                            placeholder={t("ana7r-67")}
                            required
                            // value="Enter Name"
                            onChange={(e) => setUserName(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 form-group">
                          <input
                            type="email"
                            name="email"
                            // value={email}
                            className="form--control"
                            placeholder={t("ana7r-68")}
                            required
                            onChange={(e) => setemail(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-12 form-group">
                          <input
                            type="text"
                            name="subject"
                            className="form--control"
                            placeholder={t("ana7r-69")}
                            // value="Enter Subject"
                            onChange={(e) => setsubject(e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-lg-12 form-group">
                          <textarea
                            className="form--control"
                            name="message"
                            placeholder={t("ana7r-70")}
                            required
                            // value="Enter Massage"
                            onChange={(e) => setmassage(e.target.value)}
                          ></textarea>
                        </div>
                        <div className="col-lg-12 form-group">
                          <button className="submit-btn mt-20">
                            {t("ana7r-71")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContectUs;
