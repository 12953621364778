import React from "react";
import image1 from "../../assets/images/service/64beca715fe9d1690225265.jpg";
import image2 from "../../assets/images/service/64bf0499c85f01690240153.jpg";
import image3 from "../../assets/images/service/64bf28b01dcd21690249392.png";
import image4 from "../../assets/images/service/64c00c50515ad1690307664.jpg";
import image5 from "../../assets/images/service/64a1bbd9db3181688320985.png";
import image6 from "../../assets/images/service/64a1bc09b9fe91688321033.png";
import image7 from "../../assets/images/service/64a1bc3984b4f1688321081.png";
import image8 from "../../assets/images/service/64a1bf1c5bf4b1688321820.png";
import image9 from "../../assets/images/service/64a1bf4986b6d1688321865.png";
import image10 from "../../assets/images/service/64a1bf7e7e5581688321918.png";
import image11 from "../../assets/images/service/64bd08cf74cfd1690110159.jpg";
import image12 from "../../assets/images/service/64a1c17929c5d1688322425.png";
import image13 from "../../assets/images/service/64a1c1a35b65b1688322467.png";
import image14 from "../../assets/images/service/64a1c1e17db0f1688322529.png";
import image15 from "../../assets/images/service/64a1c3aa3ca941688322986.png";
import UserImage1 from "../../assets/images/user/profile/649c93978eca21687982999.png";
import { Link } from "react-router-dom";

function ItemCard() {
  const carddata = [
    {
      name: "Malik zohaib",
      rating: "4",
      amount: "$12.00",
      title: "Front End Developer",
      image: image1,
      userimage: "",
      level: "Featured",
    },
    {
      name: "Malik sohaib",
      rating: "4",
      amount: "$12.00",
      title: "Raect js  Developer",
      image: image2,
      userimage: "",
      level: "Featured",
    },
    {
      name: "Malik Shahzad",
      rating: "4",
      amount: "$12.00",
      title: "Full Stack laraval Developer",
      image: image3,
      userimage: "",
      level: "Featured",
    },
    {
      name: "Malik Shahzad",
      rating: "4",
      amount: "$12.00",
      title: "Full Stack laraval Developer",
      image: image4,
      userimage: "",
      level: "Featured",
    },
    {
      name: "Malik Shahzad",
      rating: "4",
      amount: "$12.00",
      title: "Full Stack laraval Developer",
      image: image5,
      userimage: "",
      level: "Featured",
    },
    {
      name: "Malik Shahzad",
      rating: "4",
      amount: "$12.00",
      title: "Full Stack laraval Developer",
      image: image6,
      userimage: "",
      level: "Featured",
    },
    {
      name: "Malik Shahzad",
      rating: "4",
      amount: "$12.00",
      title: "Full Stack laraval Developer",
      image: image7,
      userimage: "",
      level: "Featured",
    },
    {
      name: "Malik Shahzad",
      rating: "4",
      amount: "$12.00",
      title: "Full Stack laraval Developer",
      image: image8,
      userimage: "",
      level: "Featured",
    },
    {
      name: "Malik Shahzad",
      rating: "4",
      amount: "$12.00",
      title: "Full Stack laraval Developer",
      image: image9,
      userimage: "",
      level: "Featured",
    },
    {
      name: "Malik Shahzad",
      rating: "4",
      amount: "$12.00",
      title: "Full Stack laraval Developer",
      image: image10,
      userimage: "",
      level: "Featured",
    },
    {
      name: "Malik Shahzad",
      rating: "4",
      amount: "$12.00",
      title: "Full Stack laraval Developer",
      image: image11,
      userimage: "",
      level: "Featured",
    },
    {
      name: "Malik Shahzad",
      rating: "4",
      amount: "$12.00",
      title: "Full Stack laraval Developer",
      image: image12,
      userimage: "",
      level: "Featured",
    },
    {
      name: "Malik Shahzad",
      rating: "4",
      amount: "$12.00",
      title: "Full Stack laraval Developer",
      image: image13,
      userimage: "",
      level: "Featured",
    },
    {
      name: "Malik Shahzad",
      rating: "4",
      amount: "$12.00",
      title: "Full Stack laraval Developer",
      image: image14,
      userimage: "",
      level: "Featured",
    },
    {
      name: "Malik Shahzad",
      rating: "4",
      amount: "$12.00",
      title: "Full Stack laraval Developer",
      image: image15,
      userimage: "",
      level: "Featured",
    },
  ];

  return (
    <>
      {carddata.map((data) => (
        <div class="item-card">
          <div class="item-card-thumb ">
            <img
              src={data.image}
              alt="Service Image"
              // style={{ border: "1px solid #E1E7EC" }}
            />
            <div class="bg-warning rounded text-white position-absolute start-0 top-0 m-3 py-1 px-2">
              {data.level}
            </div>
            {/* <div class="item-level">{data.level}</div> */}
          </div>
          <div class="item-card-content">
            <div class="item-card-content-top">
              <div class="left">
                <div class="author-thumb">
                  <img src={UserImage1} alt="Marinaadel234" />
                </div>
                <div class="author-content">
                  <h5 class="name">
                    <Link to="/user">{data.name}</Link>
                    <span class="level-text"> </span>
                  </h5>
                  <div class="ratings">
                    <i class="fas fa-star"></i>
                    <span class="rating me-2">{data.rating}</span>
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="item-amount">{data.amount}</div>
              </div>
            </div>
            <h3 class="item-card-title">
              <Link to="/service-detail">{data.title}</Link>
            </h3>
          </div>
          <div class="item-card-footer">
            <div class="left">
              <Link
                to="/"
                class="item-love me-2 loveHeartAction"
                data-serviceid="32"
              >
                <i class="fas fa-heart"></i>
                <span class="give-love-amount">(1)</span>
              </Link>
              <Link to="/" class="item-like">
                <i class="las la-thumbs-up"></i> (0)
              </Link>
            </div>
            <div class="right">
              <div class="order-btn">
                <Link to="/" class="btn--base">
                  <i class="las la-shopping-cart"></i> Order Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default ItemCard;
