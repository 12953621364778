import React from "react";
import "../src/assets/templates/basic/frontend/css/animate.css";
import "../src/assets/templates/basic/frontend/css/style.css";
import "../src/assets/templates/basic/frontend/css/swiper.min.css";
import "../src/assets/templates/basic/frontend/css/fontawesome-all.min.css";
import "../src/assets/templates/basic/frontend/css/bootstrap.min.css";
import "../src/assets/templates/basic/frontend/css/swiper.min.css";
import "../src/assets/templates/basic/frontend/css/chosen.css";
import "../src/assets/templates/basic/frontend/css/line-awesome.min.css";
import "../src/assets/templates/basic/frontend/css/bootstrap-fileinput.css";
import "../src/assets/templates/basic/frontend/css/color547b.css";
import "../src/assets/global/css/iziToast.min.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Pages/Home";
import Services from "./components/Pages/Services";
import ContectUs from "./components/ContectUS/ContectUs";
import Products from "./components/Pages/Products";
import Job from "./components/Pages/Job";
import Blogs from "./components/Pages/Blogs";
import UserLogin from "./components/UserLogin/UserLogin";
import UserSignUp from "./components/UserSignUp/UserSignUp";
import ForgetPassword from "./components/ForgetPassword/ForgetPassword";
import ConfirmPassowrd from "./components/ConfirmPassword/ConfirmPassword";
import OTP from "./components/OTP/Otp";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BlogDetail from "./components/BlogDetail/BlogDetail";
import JobsCard2 from "./components/CardsNo2/JobsCard2";
import UserProfileDetail from "./components/UserProfileDetail/UserProfileDetail";
import GigDetail from "./components/GigDetail/GigDetail";
import CategoryDetail from "./components/CategoryDetail/CategoryDetail";
import PerLoader from "./components/PreLoad/PreLoader";

function App() {
  return (
    <>
      <PerLoader />
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} index />
          <Route path="/services" element={<Services />} />
          <Route path="/products" element={<Products />} />
          <Route path="/ana7r-jobs" element={<Job />} />
          <Route path="/ana7r-blogs" element={<Blogs />} />
          <Route path="/contact" element={<ContectUs />} />
          <Route path="/login" element={<UserLogin />} />
          <Route path="/register-account" element={<UserSignUp />} />
          <Route path="/confirm-password" element={<ConfirmPassowrd />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/otp" element={<OTP />} />
          <Route path="/blog-detail" element={<BlogDetail />} />
          <Route path="/ana7r-jobs-filter" element={<JobsCard2 />} />
          <Route path="/user" element={<UserProfileDetail />} />
          <Route path="/service-detail" element={<GigDetail />} />
          <Route path="/service-category" element={<CategoryDetail />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
