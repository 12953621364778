import React from "react";
import BlogCard from "../BlogCard/BlogCard";
import Header from "../Header/Header";

function Blogs() {
  return (
    <>
      <section class="all-sections pt-30">
        <div class="container-fluid p-max-sm-0">
          <div class="sections-wrapper d-flex flex-wrap justify-content-center">
            <article class="main-section">
              <div class="section-inner">
                <div class="blog-section">
                  <div class="container-fluid">
                    <div class="row justify-content-center mb-30-none">
                      <BlogCard />
                    </div>
                    <nav></nav>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blogs;
