import React from "react";
const mystyle = {};

function Search() {
  return (
    <>
      {/* <div class="item-wrapper-right ">
        <form
          class="search-from"
          action="http://ana7r.com/service/search"
          method="GET"
        >
          <input
            type="search"
            name="search"
            class="form-control"
            value=""
            placeholder="Search Here..."
          />
          <button type="submit">
            <i class="las la-search"></i>
          </button>
        </form>
      </div> */}
      <form class="d-flex" role="search">
        <input
          style={{ borderColor: "#4EDD9B" }}
          class="form-control me-4 w-80"
          type="search"
          placeholder="Search here..."
          aria-label="Search"
        />
        <button
          class="btn btn-outline-success"
          type="submit"
          style={{
            width: "9rem",
            color: "#606975",
            borderColor: "#4EDD9B",
            backgroundColor: "white",
          }}
        >
          Search
        </button>
      </form>
    </>
  );
}

export default Search;
