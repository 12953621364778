import React from "react";
import image1 from "../../assets/images/service/64a0ff63cbf771688272739.png";
import image2 from "../../assets/images/service/64a1c1e17db0f1688322529.png";
import image3 from "../../assets/images/service/64a1c72c770811688323884.png";
import image4 from "../../assets/images/service/64a1bc09b9fe91688321033.png";
import { Link } from "react-router-dom";

function ServicesSideBar() {
  return (
    <>
      <div class="widget mb-30 ">
        <h3 class="widget-title"> FEATURED SERVICE</h3>
        <ul class="small-item-list" id="featuredService">
          <li class="small-single-item">
            <div class="thumb">
              <img src={image1} alt="service image" />
            </div>
            <div class="content">
              <h5 class="title">
                <Link to="/">
                  {" "}
                  Two-judge SC bench takes up Imran's case today
                </Link>
              </h5>
              <div class="ratings">
                <i class="fas fa-star text--warning"></i>
                <span class="rating">(3.00)</span>
                <p class="author-like d-inline-flex flex-wrap align-items-center ms-2">
                  <span class="las la-thumbs-up text--base"></span>
                  (9)
                </p>
              </div>
            </div>
          </li>
          <li class="small-single-item">
            <div class="thumb">
              <img src={image2} alt="service image" />
            </div>
            <div class="content">
              <h5 class="title">
                <Link to="/">Latest News</Link>
              </h5>
              <div class="ratings">
                <i class="fas fa-star text--warning"></i>
                <span class="rating">(2.00)</span>
                <p class="author-like d-inline-flex flex-wrap align-items-center ms-2">
                  <span class="las la-thumbs-up text--base"></span>
                  (4)
                </p>
              </div>
            </div>
          </li>
          <li class="small-single-item">
            <div class="thumb">
              <img src={image3} alt="service image" />
            </div>
            <div class="content">
              <h5 class="title">
                <Link to="/">Pakistan Win Asia Cup.</Link>
              </h5>
              <div class="ratings">
                <i class="fas fa-star text--warning"></i>
                <span class="rating">(3.00)</span>
                <p class="author-like d-inline-flex flex-wrap align-items-center ms-2">
                  <span class="las la-thumbs-up text--base"></span>
                  (8)
                </p>
              </div>
            </div>
          </li>
          <li class="small-single-item">
            <div class="thumb">
              <img src={image4} alt="service image" />
            </div>
            <div class="content">
              <h5 class="title">
                <Link to="/">PM leaves for Karachi on day-long visit</Link>
              </h5>
              <div class="ratings">
                <i class="fas fa-star text--warning"></i>
                <span class="rating">(1.00)</span>
                <p class="author-like d-inline-flex flex-wrap align-items-center ms-2">
                  <span class="las la-thumbs-up text--base"></span>
                  (6)
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}

export default ServicesSideBar;
