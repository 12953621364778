import React from "react";
import image1 from "../../assets/images/service/64b2b3f621c5e1689433078.jpg";
import UserImage1 from "../../assets/images/user/profile/649c93978eca21687982999.png";
import ReviewSection from "../ReviewSection/ReviewSection";

function UserDetailSection() {
  return (
    <>
      <div class="col-xl-9 col-lg-9 mb-30">
        <div class="item-details-area">
          <div class="product-tab">
            <nav>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  class="nav-link active"
                  id="service-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#service"
                  type="button"
                  role="tab"
                  aria-controls="service"
                  aria-selected="true"
                >
                  Services
                </button>
                <button
                  class="nav-link"
                  id="software-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#software"
                  type="button"
                  role="tab"
                  aria-controls="software"
                  aria-selected="false"
                >
                  Product
                </button>
                <button
                  class="nav-link"
                  id="job-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#job"
                  type="button"
                  role="tab"
                  aria-controls="job"
                  aria-selected="false"
                >
                  job
                </button>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="service"
                role="tabpanel"
                aria-labelledby="service-tab"
              >
                <div class="item-card-wrapper border-0 p-0 grid-view mt-30">
                  <div class="item-card">
                    <div class="item-card-thumb">
                      <img src={image1} alt="Service Image" />
                    </div>
                    <div class="item-card-content">
                      <div class="item-card-content-top">
                        <div class="left">
                          <div class="author-thumb">
                            <img src={UserImage1} alt="mrmabdo" />
                          </div>
                          <div class="author-content">
                            <h5 class="name">
                              <a href="mrmabdo.html">Malik Shahzad</a>
                              <span class="level-text">LV 3</span>
                            </h5>
                            <div class="ratings">
                              <i class="fas fa-star"></i>
                              <span class="rating me-2">5</span>
                            </div>
                          </div>
                        </div>
                        <div class="right">
                          <div class="item-amount">$13.00</div>
                        </div>
                      </div>
                      <h3 class="item-card-title">
                        <a href="">Full Stack laraval Developer</a>
                      </h3>
                    </div>
                    <div class="item-card-footer">
                      <div class="left">
                        <a
                          href="javascript:void(0)"
                          class="item-love me-2 loveHeartAction"
                          data-serviceid="4"
                        >
                          <i class="fas fa-heart"></i>
                          <span class="give-love-amount">(12)</span>
                        </a>

                        <a href="javascript:void(0)" class="item-like">
                          <i class="las la-thumbs-up"></i>
                          (30)
                        </a>
                      </div>
                      <div class="right">
                        <div class="order-btn">
                          <a href="" class="btn--base">
                            <i class="las la-shopping-cart"></i>
                            Order Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item-card">
                    <div class="item-card-thumb">
                      <img src={image1} alt="Service Image" />
                    </div>
                    <div class="item-card-content">
                      <div class="item-card-content-top">
                        <div class="left">
                          <div class="author-thumb">
                            <img src={UserImage1} alt="mrmabdo" />
                          </div>
                          <div class="author-content">
                            <h5 class="name">
                              <a href="mrmabdo.html">Malik Shahzad</a>
                              <span class="level-text">LV 3</span>
                            </h5>
                            <div class="ratings">
                              <i class="fas fa-star"></i>
                              <span class="rating me-2">5</span>
                            </div>
                          </div>
                        </div>
                        <div class="right">
                          <div class="item-amount">$13.00</div>
                        </div>
                      </div>
                      <h3 class="item-card-title">
                        <a href="">Full Stack laraval Developer</a>
                      </h3>
                    </div>
                    <div class="item-card-footer">
                      <div class="left">
                        <a
                          href="javascript:void(0)"
                          class="item-love me-2 loveHeartAction"
                          data-serviceid="4"
                        >
                          <i class="fas fa-heart"></i>
                          <span class="give-love-amount">(12)</span>
                        </a>

                        <a href="javascript:void(0)" class="item-like">
                          <i class="las la-thumbs-up"></i>
                          (30)
                        </a>
                      </div>
                      <div class="right">
                        <div class="order-btn">
                          <a href="" class="btn--base">
                            <i class="las la-shopping-cart"></i>
                            Order Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item-card">
                    <div class="item-card-thumb">
                      <img src={image1} alt="Service Image" />
                    </div>
                    <div class="item-card-content">
                      <div class="item-card-content-top">
                        <div class="left">
                          <div class="author-thumb">
                            <img src={UserImage1} alt="mrmabdo" />
                          </div>
                          <div class="author-content">
                            <h5 class="name">
                              <a href="mrmabdo.html">Malik Shahzad</a>
                              <span class="level-text">LV 3</span>
                            </h5>
                            <div class="ratings">
                              <i class="fas fa-star"></i>
                              <span class="rating me-2">5</span>
                            </div>
                          </div>
                        </div>
                        <div class="right">
                          <div class="item-amount">$13.00</div>
                        </div>
                      </div>
                      <h3 class="item-card-title">
                        <a href="">Full Stack laraval Developer</a>
                      </h3>
                    </div>
                    <div class="item-card-footer">
                      <div class="left">
                        <a
                          href="javascript:void(0)"
                          class="item-love me-2 loveHeartAction"
                          data-serviceid="4"
                        >
                          <i class="fas fa-heart"></i>
                          <span class="give-love-amount">(12)</span>
                        </a>

                        <a href="javascript:void(0)" class="item-like">
                          <i class="las la-thumbs-up"></i>
                          (30)
                        </a>
                      </div>
                      <div class="right">
                        <div class="order-btn">
                          <a href="" class="btn--base">
                            <i class="las la-shopping-cart"></i>
                            Order Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="widget-btn text-center mt-4"></div>
              </div>
              <div
                class="tab-pane fade"
                id="software"
                role="tabpanel"
                aria-labelledby="software-tab"
              >
                <div class="item-card-wrapper border-0 p-0 grid-view mt-30">
                  <div class="item-card">
                    <div class="item-card-thumb">
                      <img src={image1} alt="Service Image" />
                    </div>
                    <div class="item-card-content">
                      <div class="item-card-content-top">
                        <div class="left">
                          <div class="author-thumb">
                            <img src={UserImage1} alt="mrmabdo" />
                          </div>
                          <div class="author-content">
                            <h5 class="name">
                              <a href="mrmabdo.html">Malik Shahzad</a>
                              <span class="level-text">LV 3</span>
                            </h5>
                            <div class="ratings">
                              <i class="fas fa-star"></i>
                              <span class="rating me-2">5</span>
                            </div>
                          </div>
                        </div>
                        <div class="right">
                          <div class="item-amount">$13.00</div>
                        </div>
                      </div>
                      <h3 class="item-card-title">
                        <a href="">Full Stack laraval Developer</a>
                      </h3>
                    </div>
                    <div class="item-card-footer">
                      <div class="left">
                        <a
                          href="javascript:void(0)"
                          class="item-love me-2 loveHeartAction"
                          data-serviceid="4"
                        >
                          <i class="fas fa-heart"></i>
                          <span class="give-love-amount">(12)</span>
                        </a>

                        <a href="javascript:void(0)" class="item-like">
                          <i class="las la-thumbs-up"></i>
                          (30)
                        </a>
                      </div>
                      <div class="right">
                        <div class="order-btn">
                          <a href="" class="btn--base">
                            <i class="las la-shopping-cart"></i>
                            Order Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item-card">
                    <div class="item-card-thumb">
                      <img src={image1} alt="Service Image" />
                    </div>
                    <div class="item-card-content">
                      <div class="item-card-content-top">
                        <div class="left">
                          <div class="author-thumb">
                            <img src={UserImage1} alt="mrmabdo" />
                          </div>
                          <div class="author-content">
                            <h5 class="name">
                              <a href="mrmabdo.html">Malik Shahzad</a>
                              <span class="level-text">LV 3</span>
                            </h5>
                            <div class="ratings">
                              <i class="fas fa-star"></i>
                              <span class="rating me-2">5</span>
                            </div>
                          </div>
                        </div>
                        <div class="right">
                          <div class="item-amount">$13.00</div>
                        </div>
                      </div>
                      <h3 class="item-card-title">
                        <a href="">Full Stack laraval Developer</a>
                      </h3>
                    </div>
                    <div class="item-card-footer">
                      <div class="left">
                        <a
                          href="javascript:void(0)"
                          class="item-love me-2 loveHeartAction"
                          data-serviceid="4"
                        >
                          <i class="fas fa-heart"></i>
                          <span class="give-love-amount">(12)</span>
                        </a>

                        <a href="javascript:void(0)" class="item-like">
                          <i class="las la-thumbs-up"></i>
                          (30)
                        </a>
                      </div>
                      <div class="right">
                        <div class="order-btn">
                          <a href="" class="btn--base">
                            <i class="las la-shopping-cart"></i>
                            Order Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item-card">
                    <div class="item-card-thumb">
                      <img src={image1} alt="Service Image" />
                    </div>
                    <div class="item-card-content">
                      <div class="item-card-content-top">
                        <div class="left">
                          <div class="author-thumb">
                            <img src={UserImage1} alt="mrmabdo" />
                          </div>
                          <div class="author-content">
                            <h5 class="name">
                              <a href="mrmabdo.html">Malik Shahzad</a>
                              <span class="level-text">LV 3</span>
                            </h5>
                            <div class="ratings">
                              <i class="fas fa-star"></i>
                              <span class="rating me-2">5</span>
                            </div>
                          </div>
                        </div>
                        <div class="right">
                          <div class="item-amount">$13.00</div>
                        </div>
                      </div>
                      <h3 class="item-card-title">
                        <a href="">Full Stack laraval Developer</a>
                      </h3>
                    </div>
                    <div class="item-card-footer">
                      <div class="left">
                        <a
                          href="javascript:void(0)"
                          class="item-love me-2 loveHeartAction"
                          data-serviceid="4"
                        >
                          <i class="fas fa-heart"></i>
                          <span class="give-love-amount">(12)</span>
                        </a>

                        <a href="javascript:void(0)" class="item-like">
                          <i class="las la-thumbs-up"></i>
                          (30)
                        </a>
                      </div>
                      <div class="right">
                        <div class="order-btn">
                          <a href="" class="btn--base">
                            <i class="las la-shopping-cart"></i>
                            Order Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item-card">
                    <div class="item-card-thumb">
                      <img src={image1} alt="Service Image" />
                    </div>
                    <div class="item-card-content">
                      <div class="item-card-content-top">
                        <div class="left">
                          <div class="author-thumb">
                            <img src={UserImage1} alt="mrmabdo" />
                          </div>
                          <div class="author-content">
                            <h5 class="name">
                              <a href="mrmabdo.html">Malik Shahzad</a>
                              <span class="level-text">LV 3</span>
                            </h5>
                            <div class="ratings">
                              <i class="fas fa-star"></i>
                              <span class="rating me-2">5</span>
                            </div>
                          </div>
                        </div>
                        <div class="right">
                          <div class="item-amount">$13.00</div>
                        </div>
                      </div>
                      <h3 class="item-card-title">
                        <a href="">Full Stack laraval Developer</a>
                      </h3>
                    </div>
                    <div class="item-card-footer">
                      <div class="left">
                        <a
                          href="javascript:void(0)"
                          class="item-love me-2 loveHeartAction"
                          data-serviceid="4"
                        >
                          <i class="fas fa-heart"></i>
                          <span class="give-love-amount">(12)</span>
                        </a>

                        <a href="javascript:void(0)" class="item-like">
                          <i class="las la-thumbs-up"></i>
                          (30)
                        </a>
                      </div>
                      <div class="right">
                        <div class="order-btn">
                          <a href="" class="btn--base">
                            <i class="las la-shopping-cart"></i>
                            Order Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item-card">
                    <div class="item-card-thumb">
                      <img src={image1} alt="Service Image" />
                    </div>
                    <div class="item-card-content">
                      <div class="item-card-content-top">
                        <div class="left">
                          <div class="author-thumb">
                            <img src={UserImage1} alt="mrmabdo" />
                          </div>
                          <div class="author-content">
                            <h5 class="name">
                              <a href="mrmabdo.html">Malik Shahzad</a>
                              <span class="level-text">LV 3</span>
                            </h5>
                            <div class="ratings">
                              <i class="fas fa-star"></i>
                              <span class="rating me-2">5</span>
                            </div>
                          </div>
                        </div>
                        <div class="right">
                          <div class="item-amount">$13.00</div>
                        </div>
                      </div>
                      <h3 class="item-card-title">
                        <a href="">Full Stack laraval Developer</a>
                      </h3>
                    </div>
                    <div class="item-card-footer">
                      <div class="left">
                        <a
                          href=""
                          class="item-love me-2 loveHeartAction"
                          data-serviceid="4"
                        >
                          <i class="fas fa-heart"></i>
                          <span class="give-love-amount">(12)</span>
                        </a>

                        <a href="" class="item-like">
                          <i class="las la-thumbs-up"></i>
                          (30)
                        </a>
                      </div>
                      <div class="right">
                        <div class="order-btn">
                          <a href="" class="btn--base">
                            <i class="las la-shopping-cart"></i>
                            Order Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item-card">
                    <div class="item-card-thumb">
                      <img src={image1} alt="Service Image" />
                    </div>
                    <div class="item-card-content">
                      <div class="item-card-content-top">
                        <div class="left">
                          <div class="author-thumb">
                            <img src={UserImage1} alt="mrmabdo" />
                          </div>
                          <div class="author-content">
                            <h5 class="name">
                              <a href="mrmabdo.html">Malik Shahzad</a>
                              <span class="level-text">LV 3</span>
                            </h5>
                            <div class="ratings">
                              <i class="fas fa-star"></i>
                              <span class="rating me-2">5</span>
                            </div>
                          </div>
                        </div>
                        <div class="right">
                          <div class="item-amount">$13.00</div>
                        </div>
                      </div>
                      <h3 class="item-card-title">
                        <a href="">Full Stack laraval Developer</a>
                      </h3>
                    </div>
                    <div class="item-card-footer">
                      <div class="left">
                        <a
                          href=""
                          class="item-love me-2 loveHeartAction"
                          data-serviceid="4"
                        >
                          <i class="fas fa-heart"></i>
                          <span class="give-love-amount">(12)</span>
                        </a>

                        <a href="" class="item-like">
                          <i class="las la-thumbs-up"></i>
                          (30)
                        </a>
                      </div>
                      <div class="right">
                        <div class="order-btn">
                          <a href="" class="btn--base">
                            <i class="las la-shopping-cart"></i>
                            Order Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Job section  */}

                <div class="widget-btn text-center mt-4"></div>
              </div>

              <div
                class="tab-pane fade"
                id="job"
                role="tabpanel"
                aria-labelledby="job-tab"
              >
                <div
                  class="item-card-wrapper border-0 p-0 grid-view mt-30"
                  id="viewMoreJob"
                >
                  <div class="item-card">
                    <div class="item-card-thumb">
                      <img src={image1} alt="Service Image" />
                    </div>
                    <div class="item-card-content">
                      <div class="item-card-content-top">
                        <div class="left">
                          <div class="author-thumb">
                            <img src={UserImage1} alt="mrmabdo" />
                          </div>
                          <div class="author-content">
                            <h5 class="name">
                              <a href="mrmabdo.html">Malik Shahzad</a>
                              <span class="level-text">LV 3</span>
                            </h5>
                            <div class="ratings">
                              <i class="fas fa-star"></i>
                              <span class="rating me-2">5</span>
                            </div>
                          </div>
                        </div>
                        <div class="right">
                          <div class="item-amount">$13.00</div>
                        </div>
                      </div>
                      <h3 class="item-card-title">
                        <a href="">Full Stack laraval Developer</a>
                      </h3>
                    </div>
                    <div class="item-card-footer">
                      <div class="left">
                        <a
                          href="javascript:void(0)"
                          class="item-love me-2 loveHeartAction"
                          data-serviceid="4"
                        >
                          <i class="fas fa-heart"></i>
                          <span class="give-love-amount">(12)</span>
                        </a>

                        <a href="javascript:void(0)" class="item-like">
                          <i class="las la-thumbs-up"></i>
                          (30)
                        </a>
                      </div>
                      <div class="right">
                        <div class="order-btn">
                          <a href="" class="btn--base">
                            <i class="las la-shopping-cart"></i>
                            Order Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item-card">
                    <div class="item-card-thumb">
                      <img src={image1} alt="Service Image" />
                    </div>
                    <div class="item-card-content">
                      <div class="item-card-content-top">
                        <div class="left">
                          <div class="author-thumb">
                            <img src={UserImage1} alt="mrmabdo" />
                          </div>
                          <div class="author-content">
                            <h5 class="name">
                              <a href="mrmabdo.html">Malik Shahzad</a>
                              <span class="level-text">LV 3</span>
                            </h5>
                            <div class="ratings">
                              <i class="fas fa-star"></i>
                              <span class="rating me-2">5</span>
                            </div>
                          </div>
                        </div>
                        <div class="right">
                          <div class="item-amount">$13.00</div>
                        </div>
                      </div>
                      <h3 class="item-card-title">
                        <a href="">Full Stack laraval Developer</a>
                      </h3>
                    </div>
                    <div class="item-card-footer">
                      <div class="left">
                        <a
                          href="javascript:void(0)"
                          class="item-love me-2 loveHeartAction"
                          data-serviceid="4"
                        >
                          <i class="fas fa-heart"></i>
                          <span class="give-love-amount">(12)</span>
                        </a>

                        <a href="javascript:void(0)" class="item-like">
                          <i class="las la-thumbs-up"></i>
                          (30)
                        </a>
                      </div>
                      <div class="right">
                        <div class="order-btn">
                          <a href="" class="btn--base">
                            <i class="las la-shopping-cart"></i>
                            Order Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item-card">
                    <div class="item-card-thumb">
                      <img src={image1} alt="Service Image" />
                    </div>
                    <div class="item-card-content">
                      <div class="item-card-content-top">
                        <div class="left">
                          <div class="author-thumb">
                            <img src={UserImage1} alt="mrmabdo" />
                          </div>
                          <div class="author-content">
                            <h5 class="name">
                              <a href="mrmabdo.html">Malik Shahzad</a>
                              <span class="level-text">LV 3</span>
                            </h5>
                            <div class="ratings">
                              <i class="fas fa-star"></i>
                              <span class="rating me-2">5</span>
                            </div>
                          </div>
                        </div>
                        <div class="right">
                          <div class="item-amount">$13.00</div>
                        </div>
                      </div>
                      <h3 class="item-card-title">
                        <a href="">Full Stack laraval Developer</a>
                      </h3>
                    </div>
                    <div class="item-card-footer">
                      <div class="left">
                        <a
                          href="javascript:void(0)"
                          class="item-love me-2 loveHeartAction"
                          data-serviceid="4"
                        >
                          <i class="fas fa-heart"></i>
                          <span class="give-love-amount">(12)</span>
                        </a>

                        <a href="javascript:void(0)" class="item-like">
                          <i class="las la-thumbs-up"></i>
                          (30)
                        </a>
                      </div>
                      <div class="right">
                        <div class="order-btn">
                          <a href="" class="btn--base">
                            <i class="las la-shopping-cart"></i>
                            Order Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="widget-btn text-center mt-4">
                  <a href="job/mrmabdo.html" class="btn--base">
                    Show More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <ReviewSection />
        </div>
      </div>
    </>
  );
}

export default UserDetailSection;
