import React from "react";

function ShowMoreButton() {
  return (
    <>
      <div class="widget-btn text-center mb-30">
        <a
          href="javascript:void(0)"
          class="btn--base readMore"
          data-page="2"
          data-link="indexa54e.html?page="
          style={{ textDecoration: "none" }}
        >
          Show More
        </a>
      </div>
    </>
  );
}

export default ShowMoreButton;
