import React, { useState } from "react";
import axios from "axios";
import image1 from "../../assets/images/logoIcon/logo.png";
import { Link } from "react-router-dom";

function ConfirmPassword() {
  const [password, setpassword] = useState(" ");
  const [confirmpassword, setconfirmpassword] = useState(" ");
  function handleDate(e) {
    e.preventDefault();
    //  API post method
    axios
      .post("https://jsonplaceholder.typicode.com/users", {
        password,
        confirmpassword,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <section className="confirm-password">
        <div class="container">
          <div class="row">
            <div class="col-sm-9 col-md-7 col-lg-7 mx-auto">
              <div class="card border-0 shadow rounded-3 my-5">
                <div class="card-body p-4 p-sm-5">
                  <div className="olxlog text-center">
                    <img src={image1} alt="" />
                  </div>
                  <h5 class="ana7r-card-title text-center mb-5 mt-4">
                    Welcome to ana7r <br /> <br />
                  </h5>
                  <form onSubmit={handleDate}>
                    <div class="form-floating mb-3">
                      <input
                        type="password"
                        class="form-control"
                        id="floatingInpassword"
                        placeholder="new Password"
                        onChange={(e) => setpassword(e.target.value)}
                      />
                      <label for="floatingInput">New Password</label>
                    </div>
                    <div class="form-floating mb-3">
                      <input
                        type="password"
                        class="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        onChange={(e) => setconfirmpassword(e.target.value)}
                      />
                      <label for="floatingPassword"> Confirm Password</label>
                    </div>

                    <div class="d-grid ">
                      <Link to="/">
                        <button class="btn  btn-login  fw-bold w-100">
                          Confirm Password
                        </button>
                      </Link>
                    </div>
                    <hr class="my-4" />
                    <div className="term-and-condition mt-3">
                      <span>
                        &nbsp;
                        <span>Already Have An Account? </span> &nbsp;
                        <span>
                          <Link to="/login">Sign In</Link>
                        </span>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ConfirmPassword;
