import React, { useEffect } from "react";
import image1 from "../../assets/images/popularservices/images1.png";
import image2 from "../../assets/images/popularservices/images2.png";
import image3 from "../../assets/images/popularservices/images3.png";
import image4 from "../../assets/images/popularservices/images4.png";
import image5 from "../../assets/images/popularservices/images5.png";
import image6 from "../../assets/images/popularservices/images6.png";
import image7 from "../../assets/images/popularservices/images8.png";
import image8 from "../../assets/images/popularservices/images7.png";
import image9 from "../../assets/images/popularservices/images9.png";
import image10 from "../../assets/images/popularservices/image10.webp";
import image11 from "../../assets/images/popularservices/image11.webp";
import { Link } from "react-router-dom";
import "./popular.css";
import tEn from "../../../src/locales/En/translation.json";
import tara from "../../../src/locales/Ara/translation.json";
import { createRoot } from "react-dom/client";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: tEn,
    },
    ara: {
      translation: tara,
    },
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});
function PopularService() {
  const { t } = useTranslation();
  return (
    <>
      <section
        className="popular-service  pt-3 "
        style={{ backgroundColor: " white" }}
      >
        <div className="popular-title">
          <h2
            className="ms-4 mb-2"
            style={{ fontSize: "35px", lineHeight: "130%" }}
          >
            {t("ana7r-18")}
          </h2>
        </div>
        <div className="container-fluid">
          <div class="row ">
            <div className="col-sm-3 col-xs-3 col-md-2 col-lg-2 col-xl-2 col-xxl-3  mb-4">
              <div
                className="card"
                style={{ boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)" }}
              >
                <Link to="/user">
                  <img src={image1} className="card-img-top" alt="Card 1" />

                  <div className="card-body">
                    <h5 className="card-title" style={{ color: "black" }}>
                      <strong> {t("ana7r-11")}</strong>{" "}
                    </h5>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-sm-3 col-xs-3 col-md-2 col-lg-2 col-xl-2 col-xxl-3  mb-4">
              <div
                className="card"
                style={{ boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)" }}
              >
                <Link to="/user">
                  <img src={image5} className="card-img-top" alt="Card 1" />
                  <div className="card-body">
                    <h5 className="card-title" style={{ color: "black" }}>
                      <strong> {t("ana7r-14")}</strong>{" "}
                    </h5>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-sm-3 col-xs-3 col-md-2 col-lg-2 col-xl-2 col-xxl-3  mb-4">
              <div
                className="card"
                style={{ boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)" }}
              >
                <Link to="/user">
                  <img src={image7} className="card-img-top" alt="Card 1" />
                  <div className="card-body">
                    <h5 className="card-title" style={{ color: "black" }}>
                      <strong> {t("ana7r-15")}</strong>{" "}
                    </h5>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-sm-3 col-xs-3 col-md-2 col-lg-2 col-xl-2 col-xxl-3  mb-4">
              <div
                className="card"
                style={{ boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)" }}
              >
                <Link to="/user">
                  <img src={image2} className="card-img-top" alt="Card 1" />
                  <div className="card-body">
                    <h5 className="card-title" style={{ color: "black" }}>
                      <strong> {t("ana7r-12")}</strong>{" "}
                    </h5>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-sm-3 col-xs-3 col-md-2 col-lg-2 col-xl-2 col-xxl-3  mb-4">
              <div
                className="card"
                style={{ boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)" }}
              >
                <Link to="/user">
                  <img src={image8} className="card-img-top" alt="Card 1" />
                  <div className="card-body">
                    <h5 className="card-title" style={{ color: "black" }}>
                      <strong> {t("ana7r-10")}</strong>{" "}
                    </h5>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-sm-3 col-xs-3 col-md-2 col-lg-2 col-xl-2 col-xxl-3  mb-4">
              <div
                className="card"
                style={{ boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)" }}
              >
                <Link to="/user">
                  <img src={image3} className="card-img-top" alt="Card 1" />
                  <div className="card-body">
                    <h5 className="card-title" style={{ color: "black" }}>
                      <strong> {t("ana7r-13")}</strong>{" "}
                    </h5>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PopularService;
