import React from "react";
import Header from "../Header/Header";
import ServicesSubCet from "../ServicesSub/ServicesSubCet";
import PreLoader from "../PreLoad/PreLoader";

function Services() {
  return (
    <>
      <ServicesSubCet />
    </>
  );
}

export default Services;
