import React from "react";
import ItemCard from "../ItemCard/ItemCard";
import LevelCheckBox from "../SideBarComponents/LevelCheckBox/LevelCheckBox";

import PopularService from "../PopularService/PopularService";

import tEn from "../../../src/locales/En/translation.json";
import tara from "../../../src/locales/Ara/translation.json";
import { createRoot } from "react-dom/client";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: tEn,
    },
    ara: {
      translation: tara,
    },
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

function HomeSection() {
  const { t } = useTranslation();
  return (
    <>
      <section className="all-sections ">
        <PopularService />
        <div className="container-fluid p-max-sm-0">
          <div className="home-title">
            <h2
              className="ms-4 mb-2"
              style={{
                fontSize: "35px",
                lineHeight: "130%",
                fontWeight: "700",
              }}
            >
              {t("ana7r-19")}
            </h2>
          </div>

          <div className="sections-wrapper d-flex flex-wrap justify-content-center">
            <article className="main-section">
              <div className="section-inner">
                <div className="item-section">
                  <div className="container-fluid">
                    <div className="item-bottom-area">
                      <div className="row justify-content-center mb-30-none">
                        <div className="col-xl-3 col-lg-3 mb-30 Side-Bar ">
                          <div className="sidebar">
                            <LevelCheckBox />
                          </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 mb-30">
                          <div className="item-card-wrapper list-view">
                            <ItemCard />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeSection;
