import React from "react";
import "./footer.css";
import image1 from "../../assets/images/logoIcon/logo.png";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import image2 from "../../assets/images/logoIcon/image1.png";
import image3 from "../../assets/images/logoIcon/image2.png";
import image4 from "../../assets/images/logoIcon/images.png";
import tEn from "../../locales/En/translation.json";
import tara from "../../locales/Ara/translation.json";
import { createRoot } from "react-dom/client";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: tEn,
    },
    ara: {
      translation: tara,
    },
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

function Footer() {
  const { t } = useTranslation();
  return (
    <>
      <ScrollToTop color="#25D3B1" smooth top="30" />

      <div
        class="container-fluid bg-cust text-dark-100 footer pt-5 mt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div class="container py-5">
          <div class="row g-5">
            <div class="col-lg-3 col-md-6">
              <h5 class="text-dark mb-4">{t("ana7r-35")}</h5>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-36")}
              </a>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-58")}
              </a>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-16")}
              </a>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-59")}
              </a>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-37")}
              </a>

              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-38")}
              </a>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-39")}
              </a>
            </div>
            <div class="col-lg-3 col-md-6">
              <h5 class="text-dark mb-4"> {t("ana7r-40")}</h5>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-41")}
              </a>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-42")}
              </a>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-43")}
              </a>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-44")}
              </a>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-45")}
              </a>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-46")}
              </a>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-47")}
              </a>
            </div>
            <div class="col-lg-3 col-md-6">
              <h5 class="text-dark mb-4">{t("ana7r-48")}</h5>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-49")}
              </a>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-50")}
              </a>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-51")}
              </a>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-52")}
              </a>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-53")}
              </a>
              <a class="btn btn-link text-dark-100" href="">
                {t("ana7r-54")}
              </a>
            </div>
            <div class="col-lg-3 col-md-6 contact-icon">
              <h5 class="text-dark mb-4">{t("ana7r-9")}</h5>
              <p class="mb-2 text-dark">
                <i class="fa fa-map-marker-alt me-3"></i> {t("ana7r-55")}
              </p>
              <p class="mb-2">
                <i class="fa fa-phone-alt me-3"></i>
                <a href="tel: 0123 098 1234"> +0123 456 7890 </a>
              </p>
              <p class="mb-2">
                <i class="fa fa-envelope me-3"></i>ana7r@gmail.com
              </p>
              <div
                class="position-relative mx-auto"
                style={{ maxWidth: "400px" }}
              >
                <input
                  class="form-control bg-light w-100 py-3 ps-4 pe-5"
                  type="text"
                  placeholder="Email"
                />
                <button
                  type="button"
                  class="btn btn-primary py-1 position-absolute top-0 end-0 mt-1 me-1"
                >
                  {t("ana7r-56")}
                </button>
              </div>
              <div class="d-flex pt-2">
                <a
                  class="btn btn-outline-light btn-social"
                  href="https://twitter.com/ana7hr"
                >
                  <i class="fab fa-twitter"></i>
                </a>
                <a
                  class="btn btn-outline-light btn-social"
                  href="https://www.facebook.com/ana7hr"
                >
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a class="btn btn-outline-light btn-social" href="">
                  <i class="fab fa-instagram"></i>
                </a>
                <a class="btn btn-outline-light btn-social" href="">
                  <i class="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="copyright">
            <div class="row">
              <div class="col-md-6 text-center text-md-start mb-3 mb-md-0 footer-inner-image">
                <img src={image1} alt="" />
                &nbsp; &copy; Ana7r {t("ana7r-57")}. 2023 &nbsp;
                <a class="border-bottom" href="">
                  ana7r
                </a>
              </div>
              <div class="col-md-6 text-center text-md-end">
                <div class="footer-menu play-store-icon">
                  <a href="">
                    <img src={image2} alt="" />
                  </a>
                  <a href="">
                    <img src={image4} alt="" />
                  </a>
                  {/* <a href="">FQAs</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
