import React from "react";
import tEn from "../../locales/En/translation.json";
import tara from "../../locales/Ara/translation.json";
import { createRoot } from "react-dom/client";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: tEn,
    },
    ara: {
      translation: tara,
    },
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});
function CategoriesFilter() {
  const { t } = useTranslation();
  const mystyle = {
    a: {
      textDecoration: "none",
      color: "#606975",
    },
  };
  return (
    <>
      <div class="widget  mb-30 me-4 pe-4">
        <h3 class="widget-title">{t("ana7r-35")}</h3>
        <ul class="mycategory-list">
          <li>
            <a href="" style={mystyle.a}>
              {t("ana7r-10")}
            </a>
          </li>
          <li>
            <a href="" style={mystyle.a}>
              {t("ana7r-11")}
            </a>
          </li>
          <li>
            <a href="" style={mystyle.a}>
              {t("ana7r-12")}
            </a>
          </li>
          <li>
            <a href="" style={mystyle.a}>
              {t("ana7r-13")}
            </a>
          </li>
          <li>
            <a href="" style={mystyle.a}>
              {t("ana7r-14")}
            </a>
          </li>
          <li>
            <a href="" style={mystyle.a}>
              {t("ana7r-15")}
            </a>
          </li>
          <li>
            <a href="" style={mystyle.a}>
              {t("ana7r-16")}
            </a>
          </li>
          <li>
            <a href="" style={mystyle.a}>
              {t("ana7r-17")}
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default CategoriesFilter;
