import React from "react";
import "./levelcheckbox.css";
import tEn from "../../../locales/En/translation.json";
import tara from "../../../locales/Ara/translation.json";
import { createRoot } from "react-dom/client";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: tEn,
    },
    ara: {
      translation: tara,
    },
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

function LevelCheckBox() {
  const { t } = useTranslation();
  return (
    <>
      <form action="" method="GET">
        <div class="widget mb-30">
          <h3 class="widget-title">{t("ana7r-20")}</h3>

          <div class="form-group custom-check-group">
            <input
              type="checkbox"
              id="1.'s'"
              name="level[]"
              value="1"
              class="userLevel"
            />
            <label for="1.'s'">{t("ana7r-21")}</label>
          </div>
          <div class="form-group custom-check-group">
            <input
              type="checkbox"
              id="2.'s'"
              name="level[]"
              value="2"
              class="userLevel"
            />
            <label for="2.'s'">{t("ana7r-22")}</label>
          </div>
          <div class="form-group custom-check-group">
            <input
              type="checkbox"
              id="3.'s'"
              name="level[]"
              value="3"
              class="userLevel"
            />
            <label for="3.'s'">{t("ana7r-23")}</label>
          </div>
          <div class="form-group custom-check-group">
            <input
              type="checkbox"
              id="4.'s'"
              name="level[]"
              value="4"
              class="userLevel"
            />
            <label for="4.'s'">{t("ana7r-24")}</label>
          </div>
          <div class="form-group custom-check-group">
            <input
              type="checkbox"
              id="5.'s'"
              name="level[]"
              value="5"
              class="userLevel"
            />
            <label for="5.'s'">{t("ana7r-25")}</label>
          </div>
        </div>

        <div class="widget mb-30">
          <h3 class="widget-title">{t("ana7r-26")}</h3>
          <div class="form-group custom-check-group">
            <input
              type="checkbox"
              id="1.'f'"
              name="feature[]"
              value="1"
              class="featureService"
            />
            <label for="1.'f'">{t("ana7r-27")}</label>
          </div>
        </div>
        <div class="widget mb-30">
          <h3 class="widget-title">{t("ana7r-28")}</h3>
          <div class="widget-range-area">
            <div id="slider-range">
              <input type="range" class="form-range" id="customRange1"></input>
            </div>
            <div class="price-range">
              <div class="filter-btn">
                <button type="submit" class="btn--base active">
                  {t("ana7r-29")}
                </button>
              </div>
              <span
                className="d-flex"
                style={{
                  textAlign: "right",
                  fontWeight: "500",
                  paddingRight: "0",
                  padding: "7px",
                  width: "unset",
                  height: "unset",
                }}
              >
                $0-1000${" "}
              </span>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default LevelCheckBox;
