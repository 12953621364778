import React, { useState } from "react";
import ViewListBtn from "../ShortingComponents/ViewListBtn";
import Search from "../ShortingComponents/Search";
import CategoriesFilter from "../SideBarComponents/CategoriesFilter";
import LevelCheckBox from "../SideBarComponents/LevelCheckBox/LevelCheckBox";
import AddvertisementButton from "../SideBarComponents/AddvertisementButton";
import ServicesSideBar from "../SideBarComponents/ServicesSideBar";
import ShowMoreButton from "../SideBarComponents/ShowMoreButton";
import ProductCard from "../ProductCard/ProductCard";
import Pagination from "../ShortingComponents/Pagination";
import Header from "../Header/Header";
import ProductListCard from "../ProductListCard/ProductListCard";
import ProductGridCard from "../ProductGridCard/ProductGridCard";

function Products() {
  const [activeView, setActiveView] = useState("list"); // Default active view

  const handleViewChange = (view) => {
    setActiveView(view);
  };
  return (
    <>
      <section className="all-sections pt-60">
        <div className="container-fluid p-max-sm-0">
          <div className="sections-wrapper d-flex flex-wrap justify-content-center">
            <article className="main-section">
              <div className="section-inner">
                <div className="item-section">
                  <div className="container-fluid">
                    <div className="item-top-area d-flex flex-wrap justify-content-between align-items-center">
                      <div className="item-wrapper d-flex flex-wrap justify-content-between     align-items-center float-end">
                        <div className="item-wrapper-left d-flex flex-wrap align-items-center ">
                          <div className="item-value my-2 mb-2">
                            <span className="mt-3 mb-3">
                              Showing item &nbsp;
                              <span className="result">1 of 21</span>
                            </span>
                          </div>
                        </div>
                        <ul class="view-btn-list ">
                          <li>
                            <button
                              type="button"
                              className={`grid-view-btn list-btn ${
                                activeView === "grid" ? "active" : ""
                              }`}
                              onClick={() => handleViewChange("grid")}
                            >
                              <i class="lab la-buromobelexperte"></i>
                            </button>
                          </li>
                          <li class="">
                            <button
                              type="button"
                              className={`list-view-btn list-btn ${
                                activeView === "list" ? "active" : ""
                              }`}
                              onClick={() => handleViewChange("list")}
                            >
                              <i class="las la-list"></i>
                            </button>
                          </li>
                        </ul>
                      </div>
                      <Search />
                    </div>
                    <div className="item-bottom-area">
                      <div className="row justify-content-center mb-30-none">
                        <div className="col-xl-3 col-lg-3 mb-30 Side-Bar">
                          <div className="sidebar">
                            <CategoriesFilter />
                            <LevelCheckBox />
                            <AddvertisementButton />
                            <ServicesSideBar />
                            <ShowMoreButton />
                            <AddvertisementButton />
                          </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 mb-30">
                          {activeView === "list" ? (
                            <ProductListCard />
                          ) : (
                            <ProductGridCard />
                          )}
                        </div>
                        <Pagination />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
    </>
  );
}

export default Products;
