import React from "react";
import image1 from "../../assets/images/user/profile/64a1c52cb86a91688323372.jpg";
import image2 from "../../assets/images/user/profile/64a1c84d50a0e1688324173.jpg";

function ReviewSection() {
  return (
    <>
      <div class="product-reviews-content mt-40">
        <div class="row">
          <div class="col-xl-12">
            <div class="section-header">
              <h2 class="section-title">Buyers' opinions</h2>
            </div>
            <ul class="comment-list" id="reviewShow">
              <li class="comment-container d-flex flex-wrap">
                <div class="comment-avatar">
                  <img src={image1} alt="client" />
                </div>
                <div class="comment-box">
                  <div class="ratings-container">
                    <div class="product-ratings">
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                    </div>
                  </div>
                  <div class="comment-info mb-1">
                    <h4 class="avatar-name">zohaib hassan</h4>-
                    <span class="comment-date">01 July 2023</span>
                  </div>
                  <div class="comment-text">
                    <p>Good Job</p>
                  </div>
                </div>
              </li>
              <li class="comment-container d-flex flex-wrap">
                <div class="comment-avatar">
                  <img src={image2} alt="client" />
                </div>
                <div class="comment-box">
                  <div class="ratings-container">
                    <div class="product-ratings">
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                    </div>
                  </div>
                  <div class="comment-info mb-1">
                    <h4 class="avatar-name">shahzad Hussain</h4>-
                    <span class="comment-date">02 July 2023</span>
                  </div>
                  <div class="comment-text">
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Placeat, perferendis!
                    </p>
                  </div>
                </div>
              </li>
              <li class="comment-container d-flex flex-wrap">
                <div class="comment-avatar">
                  <img src={image2} alt="client" />
                </div>
                <div class="comment-box">
                  <div class="ratings-container">
                    <div class="product-ratings">
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                    </div>
                  </div>
                  <div class="comment-info mb-1">
                    <h4 class="avatar-name">shahzad Hussain</h4>-
                    <span class="comment-date">02 July 2023</span>
                  </div>
                  <div class="comment-text">
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Placeat, perferendis!
                    </p>
                  </div>
                </div>
              </li>
              <li class="comment-container d-flex flex-wrap">
                <div class="comment-avatar">
                  <img src={image2} alt="client" />
                </div>
                <div class="comment-box">
                  <div class="ratings-container">
                    <div class="product-ratings">
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                    </div>
                  </div>
                  <div class="comment-info mb-1">
                    <h4 class="avatar-name">shahzad Hussain</h4>-
                    <span class="comment-date">02 July 2023</span>
                  </div>
                  <div class="comment-text">
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Placeat, perferendis!
                    </p>
                  </div>
                </div>
              </li>
              <li class="comment-container d-flex flex-wrap">
                <div class="comment-avatar">
                  <img src={image2} alt="client" />
                </div>
                <div class="comment-box">
                  <div class="ratings-container">
                    <div class="product-ratings">
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                    </div>
                  </div>
                  <div class="comment-info mb-1">
                    <h4 class="avatar-name">shahzad Hussain</h4>-
                    <span class="comment-date">02 July 2023</span>
                  </div>
                  <div class="comment-text">
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Placeat, perferendis!
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewSection;
