import React from "react";
import { Link } from "react-router-dom";

function  ViewListBtn() {
  return (
    <>
      <ul class="view-btn-list ">
        <li>
          <button
            // onClick={changeview("grid-view")}
            type="button"
            class="grid-view-btn list-btn"
          >
            <i class="lab la-buromobelexperte"></i>
          </button>
        </li>
        <li class="">
          <button
            // onClick={changeview("list-view")}
            type="button"
            class="list-view-btn list-btn"
          >
            <i class="las la-list"></i>
          </button>
        </li>
      </ul>
    </>
  );
}

export default ViewListBtn;
